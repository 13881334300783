import React from "react";
import { OwnerMobile } from "../OwnerMobile";
import { useTicketData } from "../../../hooks/common";
import { useParams } from "react-router";
import TicketDetails from "../TicketDetails";
import VehicheStatus from "../VehicheStatus";
import { Feedback } from "../Feedback";
import useInterval from "../../common/useInterval";
import NotFoundPage from "../../common/NoTicketFound";
import Loading from "../../common/Loader/Loading";
import Oldfeedback from "../Oldfeedback";
import { ErrorPage } from "../ErrorPage";
import StripeContext from "../../../StripeContext";
import Payment from "../Payment";
import LandingPage from "../../LandingPage/LandingPage";
import PaymentMethodSelect from "../PaymentMethodSelect";

const VehicleOwnerContainer = () => {
  const { ticketId } = useParams();
  const { data, refetch, isLoading } = useTicketData(ticketId);
  const interval = 5000; // 5 seconds

  // Use the useInterval hook to refetch the query at the specified interval
  useInterval(() => {
    refetch();
  }, interval);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {/* {!data ? <NotFoundPage /> : null} */}
      {!data ? <LandingPage /> : null}
      {data && !data?.ownerPhoneNumber ? (
        <OwnerMobile ticketId={data?.ticketId} price={data?.hourlyPrice} />
      ) : null}
      {data?.parkingStatus &&
      !data?.deliveryTimeInMinutes &&
      data?.ownerPhoneNumber &&
      data?.parkingStatus === "Parked" ? (
        <TicketDetails
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          countryCode={data?.code}
          countryCodeId={data?.countryCodeId}
          attendentName={data?.attendentName}
          vehicleNumber={data?.vihicleNumber}
          parkingTime={data?.parkingTime}
          // isWaiting={data?.parkingStatus === "Waiting" ? true : false}
          price={data?.hourlyPrice}
          isPaymentMethodCreatedOrSelected={
            data?.isPaymentMethodCreatedOrSelected
          }
        />
      ) : null}

      {data?.parkingStatus &&
      !data?.deliveryTimeInMinutes &&
      data?.ownerPhoneNumber &&
      data?.isPaymentMethodCreatedOrSelected != true &&
      data?.parkingStatus === "Waiting" ? (
        <StripeContext>
          <PaymentMethodSelect
            ownerContact={data?.ownerPhoneNumber}
            countryCode={data?.code}
          />
        </StripeContext>
      ) : null}

      {
      data?.isPaymentMethodCreatedOrSelected == false &&
      data?.parkingStatus === "Delivered" ? (
        <StripeContext>
          <PaymentMethodSelect
            ownerContact={data?.ownerPhoneNumber}
            countryCode={data?.code}
          />
        </StripeContext>
      ) : null}

      {data?.parkingStatus &&
      !data?.deliveryTimeInMinutes &&
      data?.ownerPhoneNumber &&
      data?.parkingStatus === "Waiting" &&
      data?.isPaymentMethodCreatedOrSelected == true ? (
        <TicketDetails
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          countryCode={data?.code}
          countryCodeId={data?.countryCodeId}
          attendentName={data?.attendentName}
          vehicleNumber={data?.vihicleNumber}
          parkingTime={data?.parkingTime}
          isWaiting={data?.parkingStatus === "Waiting" ? true : false}
          price={data?.hourlyPrice}
          isPaymentMethodCreatedOrSelected={
            data?.isPaymentMethodCreatedOrSelected
          }
        />
      ) : null}

      {data?.deliveryTimeInMinutes && data?.parkingStatus !== "Delivered" ? (
        <VehicheStatus
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          countryCode={data?.code}
          attendentName={data?.attendentName}
          vehicleNumber={data?.vihicleNumber}
          parkingTime={data?.parkingTime}
          parkingStatus={data?.parkingStatus}
          time={parseInt(data?.deliveryTimeInMinutes)}
          startInterval={data?.deliveryStaringIntervel}
          price={data?.hourlyPrice}
        />
      ) : null}
{/* 
{data?.parkingStatus === "Delivered" &&
      data?.isPaid === false &&
      data?.isPaymentMethodCreatedOrSelected != true ? (
        <StripeContext>
          <PaymentMethodSelect
            ownerContact={data?.ownerPhoneNumber}
            countryCode={data?.code}
          />
        </StripeContext>
      ) : null} */}
      {data?.parkingStatus === "Delivered" &&
      data?.isPaid === false &&
      data?.isPaymentMethodCreatedOrSelected == true ? (
        <StripeContext>
          <Payment
            deliveryTime={data?.deliveryTime}
            parkingTime={data?.parkingTime}
            hourlyPrice={data?.hourlyPrice}
            amount={data?.ticketBill?.amount}
            parkingDuration={data?.ticketBill?.parkingDuration}
            ownerContact={data?.ownerPhoneNumber}
            countryCode={data?.code}
          />
        </StripeContext>
      ) : null}

      {data?.isPaid === true && data?.isFeedbackAvailable === false ? (
        <Oldfeedback />
      ) : null}
      {/* {data?.isFeedbackAvailable === false && <Oldfeedback /> ? null : (
        <ErrorPage />
      )} */}

      {/* {data?.parkingStatus === "Delivered" ? <Payment /> : null} */}
      {data?.isFeedbackAvailable == true ? <LandingPage /> : null}
    </>
  );
};

export default VehicleOwnerContainer;
