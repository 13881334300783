import React, { useState } from "react";
import Logo from "../../Logo";
import main_image from "../../assets/imgs/main-img.png";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import mobile_svg from "../../assets/imgs/icons/mobile-svg.svg";
import contect_svg from "../../assets/imgs/icons/contect-svg.svg";
import car_svg from "../../assets/imgs/icons/car-svg.svg";
import PaymentCharges from "../../assets/imgs/icons/PaymentCharges.svg";
import calender_svg from "../../assets/imgs/icons/calender-svg.svg";
import Timer from "../common/Timer";
import {
  useRequestVehicle,
  useEasternTime,
  useReparkVehicle,
} from "../../hooks/common";
import IncrementTimer from "../common/IncrementTimer";
import { useParams } from "react-router";
import VerticallyCenteredModal from "../common/Modal";
import { getMonth } from "../../utils/getMonth";
import Loading from "../common/Loader/Loading";

const VehicheStatus = ({
  ticketId,
  ownerContact,
  countryCode,
  attendentName,
  vehicleNumber,
  parkingTime,
  parkingStatus,
  startInterval,
  time,
  price
}) => {
  const { ticketId: ticketIdBackend } = useParams();
  const [show, setShow] = useState(false);
  const [timeEscalated, setTimeEscalated] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const { data: EasternTime, isLoading: getEasternTime } = useEasternTime();
  const handleSuccess = ()=>{};
  const { mutate: reparkVehicle, isLoading: getReparkVehicle } = useReparkVehicle(handleSuccess);
  const handleTimerEnd = () => {
    reparkVehicle(ticketIdBackend);
    setIsRequested(false);
    setTimeEscalated(true);
  };

  const toggleModal = () => {
    setShow((prevState) => {
      return !prevState;
    });
  };
  const { mutate, isLoading } = useRequestVehicle(handleSuccess);
  const handleSubmit = () => {
    mutate({ ticketIdBackend });
  };

  function calculateTimeDifference(date1, date2) {
    const oneSecond = 1000; // Number of milliseconds in one second
    const oneMinute = oneSecond * 60; // Number of milliseconds in one minute
    const oneHour = oneMinute * 60; // Number of milliseconds in one hour
    const oneDay = oneHour * 24; // Number of milliseconds in one day

    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    // Calculate the difference in milliseconds
    const diffMilliseconds = Math.abs(firstDate - secondDate);

    // Calculate the difference in days, hours, minutes, and seconds
    const days = Math.floor(diffMilliseconds / oneDay);
    const hours = Math.floor((diffMilliseconds % oneDay) / oneHour);
    const minutes = Math.floor((diffMilliseconds % oneHour) / oneMinute);
    const seconds = Math.floor((diffMilliseconds % oneMinute) / oneSecond);

    return { days, hours, minutes, seconds };
  }

  const handleCalculateTimeRemaining = () => {
    const { days, hours, minutes, seconds } = calculateTimeDifference(
      EasternTime,
      startInterval
    );
    if (days === 0 && hours === 0) {
      let timePassed = minutes * 60 + seconds;
      let selectedIntervalInSeconds = time * 60;
      if (selectedIntervalInSeconds > timePassed) {
        return selectedIntervalInSeconds - timePassed;
      } else {
        return 0;
      }
    } else return 0;
  };
if(isLoading || getReparkVehicle || getEasternTime){
  return <Loading/>;
}
  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        <div className="col-lg-5 p-4 p-md-5">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7 side-card p-4 p-md-5">
        
          {timeEscalated ? (
            <h4 className="bold mb-0">
              Your time is <span className="text-primary bold">Escalated</span>
            </h4>
          ) : null}
          {parkingStatus === "Waiting" ? (
            <h4 className="bold mb-0">
              Your vehicle request had been <span className="text-primary bold"> accepted by {attendentName}</span>
            </h4>
          ) : null}
          {!parkingStatus === "Waiting" && !timeEscalated ? (
            <h4 className="bold mb-0">
              Request to <span className="text-primary bold">Receive</span> your
              Vehicle
            </h4>
          ) : null}
          {timeEscalated ? (
            <p className="mobile-text mb-0" >
              Your time is Escalated, Submit you request again to Receive you
              vehicle
            </p>
          ) : null}
          {parkingStatus === "Waiting" ? (
            <p className="mobile-text">Please make your way to the valet stand! Your vehicle will arrive in:</p>
          ) : null}
          {!timeEscalated && !parkingStatus === "Waiting" ? (
            <p className="mobile-text mb-0">
              The attendant will drop your vehicle to you in this time period
            </p>
          ) : null}
          <div className="d-flex align-items-baseline">
            {timeEscalated ? (
              <IncrementTimer />
            ) : (
              EasternTime && (
                <Timer
                  initialTime={parseInt(handleCalculateTimeRemaining())}
                  onTimerEnd={handleTimerEnd}
                />
              )
            )}
            {timeEscalated ? (
              <p className=" mx-2 align-self-end text-primary">
                Time Escalated
              </p>
            ) : (
              <p className="mx-2"> Minutes Remaining </p>
            )}
          </div>
          {timeEscalated ? (
            <button
              type="button"
              className="btn rounded-4 px-5 mt-3 medium btn-primary submit-button"
              // data-bs-toggle="modal"
              // data-bs-target="#confirmodal"
              onClick={() => {
                setTimeEscalated(false);
                setIsRequested(true);
                toggleModal();
              }}
            >
              Request to Receive
            </button>
          ) : null}

          <div className="pt-4 pt-md-5">
          <h4 className="bold mb-3">
            <span className="text-primary bold">Ticket</span> Details
          </h4>
          <div className="gray-bg details-section p-0 p-md-3 rounded-3 ">
            <div className="row g-2">
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={ticket_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Parking Ticket</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{ticketId}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={mobile_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Owner Contact</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{countryCode} {ownerContact}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={contect_svg} alt="" srcSet="" />
                  <p className="m-0  w-100">Attendant Name</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{attendentName}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={car_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Vehicle License Plate #</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{vehicleNumber}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={calender_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Parked at</p>
                </div>
                <p className="semibold properties ms-4 mb-0">
                  {`${getMonth(
                    String(new Date(parkingTime).getMonth())
                  )} ${String(new Date(parkingTime).getDate()).padStart(
                    2,
                    "0"
                  )}, ${new Date(parkingTime).getFullYear()} ${String(
                    new Date(parkingTime).getHours()
                  ).padStart(2, "0")}:${String(
                    new Date(parkingTime).getMinutes()
                  ).padStart(2, "0")}`}
                </p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={PaymentCharges} alt="" srcSet="" />
                  <p className="m-0 w-100 d-flex align-items-center">
                    Parking Charges
                  </p>
                </div>
                <p className="semibold properties ms-4 mb-0">${price}/hour</p>
              </div>
            </div>
          </div>

          </div>

        </div>
      </div>
      <VerticallyCenteredModal
        show={show}
        closeModal={toggleModal}
        type="error"
        actionName="confirm"
        handleAction={handleSubmit}
        subtitle="By Clicking Yes, We will deliver yours car"
        title=" Are You Sure? You want to receive your car"
      />
    </div>
  );
};

export default VehicheStatus;
