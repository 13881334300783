import Modal from "react-bootstrap/Modal";
export const VerticallyCenteredModal = ({
  type = "success",
  title,
  subtitle,
  show,
  closeModal,
  actionName,
  handleAction,
  SelectValue,
  SelectOnChange,
  SelectOptions,
  disabled
}) => {
  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={closeModal}
      >
        <div className="modal-header">
        <h5 className="modal-title medium">
        Attendent Name
          </h5>
          <button
            type="button"
            className="btn-close small"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <Modal.Body>
            <div>
              <div className="col-12">
                <label className=" small  mb-2">Update Attendent Name</label>
                <select
                  className="form-select form-select-md"
                  onChange={SelectOnChange}
                  value={SelectValue}
                >
                  <option value={0} selected>
                    Select one
                  </option>

                  {SelectOptions?.map((Options) => (
                    <option key={Options?.id} value={Options?.id}>
                      {Options?.attendantName}
                    </option>
                  ))}
                </select>
              </div>
              <h6 className="semibold">{title}</h6>
              <p className="">{subtitle}</p>
              <div className="d-flex justify-content-end gap-3">
                {actionName ? (
                  <button
                    type="button"
                    className="btn rounded-4 px-3  btn-primary text-uppercase"
                    // data-bs-toggle="modal"
                    // data-bs-target="#successfullmessage"
                    disabled={disabled}
                    onClick={() => {
                      handleAction && handleAction();
                      closeModal();
                    }}
                  >
                    {actionName}
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn btn-close-modal px-3 rounded-5 text-uppercase"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerticallyCenteredModal;
