import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51PWR0fINQmKFSThS1PQHPb1MfOkAlCFeXemzUlku2b9ryXGG9bDgrWVahJCfZqxQVNAApCZ4YsPscpq4O5MR0Gfv002Tm6VHtm');

const StripeContext = ({ children }) => (
  <Elements stripe={stripePromise}>
    {children}
  </Elements>
);

export default StripeContext;
