import React, { useState } from "react";
import inputmobilesvg from "../../assets/imgs/icons/input-mobile-svg.svg";
import main_image from "../../assets/imgs/main-img.png";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import PaymentCharges from "../../assets/imgs/icons/PaymentCharges.svg";
import VerticallyCenteredModal from "../common/Modal";
import Logo from "../../Logo";
import {
  useUpdateOwnerPhoneNumber,
  useCountryCodesGetAll,
} from "../../hooks/common";
import { useParams } from "react-router";
import Spinner from "../common/spinner/spinner";
import Loading from "../common/Loader/Loading";
import { Link } from "react-router-dom";

export const OwnerMobile = ({ ticketId, price }) => {
  const { ticketId: ticketIdBackend } = useParams();
  const [show, setShow] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(1);

  const toggleModal = () => {
    setShow((prevState) => {
      return !prevState;
    });
  };
  // const handleUpdatePhoneNumberSuccess = (statusCode) => {
  //   if (statusCode === 200) toggleModal();
  // };
  const { mutate, isLoading } = useUpdateOwnerPhoneNumber();
  const { data, isLoading: CountryCodesLoading } = useCountryCodesGetAll();
  const handlePhoneNumberChange = (event) => {
    const noSpecialChars = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "").slice(0, 25);
    setPhoneNumber(noSpecialChars);
  };
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };
  const handleSubmit = () => {
    // let number = `${countryCode}${phoneNumber}`;
    mutate({
      phoneNumber: phoneNumber,
      ticketIdBackend,
      countryCodeId: countryCode,
    });
  };
  if (isLoading || CountryCodesLoading) {
    return <Loading />;
  }
  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        {/* <!-- *********=========COL SATRTED=========*********  --> */}
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        {/* <!-- *********=========COL CARDS=========*********  --> */}
        <div className="col-lg-7 side-card p-4 p-md-5">
          <h4 className="bold">
            Enter your <span className="text-primary bold"> Phone Number </span>{" "}
            to receive Real-Time Updates
          </h4>
          <div className="col-sm-6">
            <label className=" small  mb-2">Phone Number</label>
            <div className="d-flex align-items-center  px-2 rounded-2 mb-2 svg-input">
              <img src={inputmobilesvg} alt="" srcSet="" />
              <div className="d-flex">
                <select
                  style={{ maxWidth: "30%" }}
                  onChange={(e) => {
                    handleCountryCodeChange(e);
                  }}
                  className="p-2 border-0  text-dark outline-0 form-control"
                >
                  {data?.map((options) => (
                    <option key={options.id} value={options.id}>
                      {options.code}({options.country})
                    </option>
                  ))}
                </select>
                <input
                  style={{ maxWidth: "70%" }}
                  type="number"
                  onChange={handlePhoneNumberChange}
                  onWheel={(e) => e.target.blur()}
                  value={phoneNumber}
                  placeholder="Please enter"
                  className="flex-grow-1 p-2 border-0 outline-0 text-dark form-control"
                />
              </div>
            </div>
            <button
              type="button"
              className="btn rounded-4 px-5 mt-3 medium btn-primary submit-button text-uppercase"
              // data-bs-toggle="modal"
              // data-bs-target="#successfullmessage"
              onClick={handleSubmit}
              disabled={!phoneNumber}
            >
              {isLoading ? <Spinner /> : "Submit"}
            </button>
          </div>
          <p className=" small mb-2 mt-3">
            By selecting SUBMIT, you agree to ValetXpress's Terms of Service &
            opt-in to receive SMS notifications about your vehicle status.
            Standard SMS rates will apply. Reply "STOP" to opt-out of SMS
            notifications.{" "}
            <Link
              className="text-primary"
              to="https://valetxpress.io/doc/ValetXpress%20Payment%20Terms%20of%20Service.pdf"
              target="_blank"
            >
              Click here
            </Link>{" "}
            to review ValetXpress's Terms of Service
          </p>

          <div className="pt-4 pt-md-5">
            <h4 className="bold mb-3">
              <span className="text-primary bold">Ticket</span> Details
            </h4>
            <div className="gray-bg details-section p-0 p-md-3 rounded-3 ">
              <div className="row g-2">
                <div className="col-lg-4 col-md-6 vehicle-info ">
                  <div className="d-flex align-items-center">
                    <img className="me-2" src={ticket_svg} alt="" srcSet="" />
                    <p className="m-0   w-100">Parking Ticket #:</p>
                  </div>
                  <p className="semibold properties ms-4 mb-0">{ticketId}</p>
                </div>
                <div className="col-lg-4 col-md-6 vehicle-info ">
                  <div className="d-flex align-items-center">
                    <img
                      className="me-2"
                      src={PaymentCharges}
                      alt=""
                      srcSet=""
                    />
                    <p className="m-0 w-100 d-flex align-items-center">
                      Parking Charges
                    </p>
                  </div>
                  <p className="semibold properties ms-4 mb-0">${price}/hour</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VerticallyCenteredModal
        show={show}
        closeModal={toggleModal}
        title=" Phone Number Added Successfully...!"
        subtitle="  You will receive parking details on your phone number via SMS."
      />
    </div>
  );
};
