import React from 'react'
import './Loading.css'
const Loading = () => {
  return (
    <div className="Spinner">
    <div className="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    </div>
  )
}

export default Loading