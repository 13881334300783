import Modal from "react-bootstrap/Modal";
import PaymentErrorIcon from "../../../assets/imgs/icons/PaymentErrorIcon.svg";

export const ErrorPaymentModal = ({ show, closeModal }) => {
  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={closeModal}
      >
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn-close small"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <Modal.Body>
          <div className="text-center">
            <div className="mb-4">
              <img src={PaymentErrorIcon} alt="" />
            </div>
            <h5 className="semibold">Transaction Failed...! </h5>
            <p className="mb-0">Your transaction could not be completed. </p>
            <p className="">Please try again.</p>

            <div className="d-flex justify-content-center gap-3 mb-2">
              <button
                type="button"
                className="btn rounded-4 px-5  btn-primary "
                onClick={closeModal}
              >
                TRY AGAIN
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ErrorPaymentModal;
