import React from "react";
import Logo from "../../Logo";
import error_img from "../../assets/imgs/error-page.png";
const NotFoundPage = ({ ticketId }) => {
  return (
    <div className="container-fluid full-height-error-page">
      <div className="p-4 p-md-5">
        <a
          style={{ color: "inherit" }}
          target="_blank"
          href="https://valetxpress.io:6072/Attendant"
        >
          <Logo />
        </a>
      </div>
      <div className="row flex-wrap-reverse justify-content-center">
        <div className="col-lg-6 p-4 p-md-5">
          <div>
            <h4 className="semibold mt-lg-5">Whoops...!</h4>
            <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
              Ticket Not Found
            </h1>
            <p>
              log into the{" "}
              <a
                style={{ color: "inherit" }}
                target="_blank"
                href="https://valetxpress.io:6072/Attendant"
              >
                ValetXpress
              </a>{" "}
              admin panel to confirm delivery, edit information, or get more
              insight about ticket# {ticketId}
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-sm-8 mb-0 p-4 p-md-5">
          <img
            src={error_img}
            className="object-fit-cover h-100 w-100"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default NotFoundPage;
