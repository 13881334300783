import {
  getTicketByID,
  updateOwnerPhoneNumber,
  updateVehicleNumber,
  requestVehicle,
  getVehicleDeliveryTimeSlots,
  setVehicleDeliveryTime,
  deliverVehicle,
  setFeedback,
  reparkVehicle,
  getEasternTime,
  getParkingLocationGetAttendents,
  PutUpdateAttendent,
  getCountryCodesGetAll,
  getIsOwnerPhoneNumberExist,
  getTicketsByOwnerPhoneNumber,
  getOTPVerification,
  postProcessPayment,
  getTicketBill,
  postContactUs,
  postSetupIntentCreate,
  postPaymentMethodCreate,
  getPaymentMethodsGetAll,
  postPaymentCheckout,
  postDefautPaymentMethod,
  postPaymentMethodDelete,
  postPaymentMethodCreationOrSettingDefault,
} from "../../services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useTicketData = (ticketId) => {
  return useQuery(["ticket"], () => getTicketByID(ticketId), {
    select: (data) => {
      return data.data;
    },
  });
};

export const useEasternTime = () => {
  return useQuery(["easternTime"], () => getEasternTime(), {
    select: (data) => {
      return data.data;
    },
  });
};

export const useDeliverVehicle = (handleSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(
    (ticketId) => {
      return deliverVehicle(ticketId);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
        handleSuccess(data.statusCode);
      },
    }
  );
};
export const useReparkVehicle = (handleSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(
    (ticketId) => {
      return reparkVehicle(ticketId);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
        handleSuccess(data.statusCode);
      },
    }
  );
};

export const useSetFeedback = (handleSuccess) => {
  return useMutation(
    (data) => {
      return setFeedback(data.ticketId, data.stars, data.feedback);
    },
    {
      onSuccess: (data) => {
        handleSuccess(data.statusCode);
      },
    }
  );
};

export const useVehicleDeliverySlots = () => {
  return useQuery(
    ["vehicleDeliverySlots"],
    () => getVehicleDeliveryTimeSlots(),
    {
      select: (data) => {
        return data.data.split(",");
      },
    }
  );
};

export const useUpdateOwnerPhoneNumber = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return updateOwnerPhoneNumber(
        data.ticketIdBackend,
        data.phoneNumber,
        data.countryCodeId
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
      },
    }
  );
};

export const useSetVehicleDeliveryTime = (handleSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return setVehicleDeliveryTime(data.ticketIdBackend, data.time);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
        handleSuccess(data.statusCode);
      },
    }
  );
};

export const useUpdateVehicleNumber = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return updateVehicleNumber(data.ticketIdBackend, data.vehicleNumber);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ticket"]);
      },
    }
  );
};

export const useRequestVehicle = (handleSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return requestVehicle(data.ticketIdBackend);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
        handleSuccess(data.statusCode);
      },
    }
  );
};
export const useParkingLocationGetAttendents = (ticketId) => {
  return useQuery(
    ["ParkingLocationGetAttendents"],
    () => getParkingLocationGetAttendents(ticketId),
    {
      select: (data) => {
        return data.data;
      },
    }
  );
};
export const useSetUpdateAttendent = () => {
  const QueryClient = useQueryClient();
  return useMutation(
    (data) => {
      return PutUpdateAttendent(data.id, data.updatedAttendentId);
    },
    {
      onSuccess: () => {
        QueryClient.invalidateQueries("ParkingLocationGetAttendents");
      },
    }
  );
};
export const useCountryCodesGetAll = (ticketId) => {
  return useQuery(
    ["CountryCodesGetAll"],
    () => getCountryCodesGetAll(ticketId),
    {
      select: (data) => {
        return data.data;
      },
    }
  );
};

export const useIsOwnerPhoneNumberExist = (loginSuccessData) => {
  return useMutation(
    (data) => {
      return getIsOwnerPhoneNumberExist(data.countryCodeId, data.phoneNumber);
    },
    {
      onSuccess: (data) => {
        loginSuccessData(data);
        return data;
      },
    }
  );
};
export const useTicketsByOwnerPhoneNumber = (
  page,
  rowPerPage,
  countryCode,
  phoneNumber
) => {
  return useQuery(
    ["getTicketsHistory", { page, rowPerPage }],
    () =>
      getTicketsByOwnerPhoneNumber(page, rowPerPage, countryCode, phoneNumber),
    {
      select: (data) => {
        return { list: data.data };
      },
    }
  );
};
export const useOTPVerification = (otpOnSuccess) => {
  return useMutation(
    (data) => {
      return getOTPVerification(data.phoneNumber, data.otp, data.countryCodeId);
    },
    {
      onSuccess: (data) => {
        otpOnSuccess(data);
        return data;
      },
    }
  );
};

export const usePostProcessPayment = (onSuccess, onError) => {
  const QueryClient = useQueryClient();
  return useMutation(
    (data) => {
      return postProcessPayment(data);
    },
    {
      onSuccess: (data) => {
        QueryClient.invalidateQueries("ticket");
        onSuccess(data);
        return data;
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const useTicketBill = (ticketId) => {
  return useQuery(["TicketBill"], () => getTicketBill(ticketId), {
    select: (data) => {
      return data.data;
    },
  });
};



export const usePostContactUs = (onSuccess, onError) => {
  return useMutation(
    (data) => {
      return postContactUs(data);
    },
    {
      onSuccess: (data) => {
        onSuccess(data);
        return data;
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const usePaymentMethodsGetAll = (phoneNumber) => {
  return useQuery(["usePaymentMethodsGetAll"], () => getPaymentMethodsGetAll(phoneNumber), {
    select: (data) => {
      return data;
    },
  });
};

export const useSetupIntentCreate = (phoneNumber, onSuccess) => {
  return useQuery(["useSetupIntentCreate"], () => postSetupIntentCreate(phoneNumber), {
    select: (data) => {
      return data;
    },
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    }
  });
};


export const usePostDefautPaymentMethod = (onSuccess, onError) => {
  const QueryClient = useQueryClient();
  return useMutation(
    (phoneNumber) => {
      return postDefautPaymentMethod(phoneNumber);
    },
    {
      onSuccess: (data) => {
        QueryClient.invalidateQueries("ticket");
        onSuccess(data);
        return data;
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};


export const usePaymentMethodCreate = (onSuccess, onError) => {
  const QueryClient = useQueryClient();
  return useMutation(
    (phoneNumber) => {
      return postPaymentMethodCreate(phoneNumber);
    },
    {
      onSuccess: (data) => {
        QueryClient.invalidateQueries("ticket");
        onSuccess(data);
        return data;
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const usePostPaymentCheckout = (onSuccess, onError) => {
  const QueryClient = useQueryClient();
  return useMutation(
    (data) => {
      return postPaymentCheckout(data);
    },
    {
      onSuccess: (data) => {
        QueryClient.invalidateQueries("ticket");
        onSuccess(data);
        return data;
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const usePostPaymentMethodDelete = (onSuccess, onError) => {
  const QueryClient = useQueryClient();
  return useMutation(
    (id) => {
      return postPaymentMethodDelete(id);
    },
    {
      onSuccess: (data) => {
        QueryClient.invalidateQueries("usePaymentMethodsGetAll");
        onSuccess(data);
        return data;
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};

export const usePaymentMethodCreationOrSettingDefault = (onSuccess, onError) => {
  const QueryClient = useQueryClient();
  return useMutation(
    (id) => {
      return postPaymentMethodCreationOrSettingDefault(id);
    },
    {
      onSuccess: (data) => {
        QueryClient.invalidateQueries("ticket");
        onSuccess(data);
        return data;
      },
      onError: (error) => {
        onError(error);
      },
    }
  );
};