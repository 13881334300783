import React, { useEffect, useState } from "react";
import "./Landing.scss";
import introductionImage from "../../assets/imgs/Introduction-Image.png";
import StarIcon from "../../assets/imgs/icons/StarIcon.png";
import QrIcon from "../../assets/imgs/icons/QrIcon.png";
import RequestVehicleIcon from "../../assets/imgs/icons/RequestVehicleIcon.png";
import BellIcon from "../../assets/imgs/icons/BellIcon.png";
import NasaIcon from "../../assets/imgs/icons/NasaIcon.png";
import DysonIcon from "../../assets/imgs/icons/DysonIcon.png";
import BelleIcon from "../../assets/imgs/icons/BelleIcon.png";
import FooterLinkIcon from "../../assets/imgs/icons/FooterLinkIcon.svg";
import StripeIconLanding from "../../assets/imgs/icons/StripeIconLanding.png";
import ApplePayIconLanding from "../../assets/imgs/icons/ApplePayIconLanding.png";
import PayPalIconLanding from "../../assets/imgs/icons/PayPalIconLanding.png";
import MessageIconFooter from "../../assets/imgs/icons/MessageIconFooter.svg";

import PersonalizedCustomerCareImage from "../../assets/imgs/SliderImages/PersonalizedCustomerCareImage.png";
import RealTimeStatusUpdatesImage from "../../assets/imgs/SliderImages/RealTimeStatusUpdatesImage.png";
import ConvenienceEfficiencyImage from "../../assets/imgs/SliderImages/ConvenienceEfficiencyImage.png";
import EnhancedParkingManagementLogisticsImage from "../../assets/imgs/SliderImages/EnhancedParkingManagementLogisticsImage.png";
import CashlessContactFreeExperienceImage from "../../assets/imgs/SliderImages/CashlessContactFreeExperienceImage.png";
import PremiumValetNetworkImage from "../../assets/imgs/SliderImages/PremiumValetNetworkImage.png";
import contactScreenIcon from "../../assets/imgs/contactScreenIcon.png";

import ArrivalTicketingImg from "../../assets/imgs/SliderImages/ArrivalTicketingImage.png";
import ContactlessVehicleRetrievalImage from "../../assets/imgs/SliderImages/ContactlessVehicleRetrievalImage.png";
import ElectronicPaymentsImage from "../../assets/imgs/SliderImages/ElectronicPaymentsImage.png";
import RealTimeUpdatesImage from "../../assets/imgs/SliderImages/RealTimeUpdatesImage.png";
import ParkingExperienceImage from "../../assets/imgs/ParkingExperienceImage.png";
import FooterLogo from "../../assets/imgs/logos/FooterLogo.png";
import LogoLight from "../../assets/imgs/logos/LogoLight.png";
import LogoDark from "../../assets/imgs/logos/LogoDark.png";

import { ReactComponent as YoutubeIconFooter } from "../../assets/imgs/icons/YoutubeIconFooter.svg";
import { ReactComponent as InstagramIconFooter } from "../../assets/imgs/icons/InstagramIconFooter.svg";
import { ReactComponent as FacebookIconFooter } from "../../assets/imgs/icons/FacebookIconFooter.svg";
import { ReactComponent as TwitterIconFooter } from "../../assets/imgs/icons/TwitterIconFooter.svg";
import "bootstrap/dist/js/bootstrap.bundle.min";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { usePostContactUs } from "../../hooks/common";
import { Link } from "react-router-dom";
import { Toast, ToastContainer } from "react-bootstrap";
const LandingPage = () => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
    document.body.style.backgroundColor = "#FFF6F8";
    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundColor = "";
    };
  }, []);
  const [scrolled, setScrolled] = useState(false);
  const [innerWidth, setInnerWidth] = useState(false);
  useEffect(() => {
    if (window.scrollY > 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setInnerWidth(true);
    } else {
      setInnerWidth(false);
    }
    const updateWidth = () => {
      if (window.innerWidth < 992) {
        setInnerWidth(true);
      } else {
        setInnerWidth(false);
      }
    };
    window.addEventListener("resize", updateWidth);
    updateWidth();
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [window.innerWidth]); // eslint-disable-line

  const [activeSection, setActiveSection] = useState("Home");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      let currentSection = "";

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        if (window.scrollY >= sectionTop - 50) {
          currentSection = section.getAttribute("id");
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (section) => {
    // setActiveSection(section);
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");

  const [FullNameError, setFullNameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [PhoneNoError, setPhoneNoError] = useState("");
  const [SubjectError, setSubjectError] = useState("");
  const [MessageError, setMessageError] = useState("");

  const handleFocusFullName = () => {
    setFullNameError("");
  };
  const handleFocusEmail = () => {
    setEmailError("");
  };
  const handleFocusPhoneNo = () => {
    setPhoneNoError("");
  };
  const handleFocusSubject = () => {
    setSubjectError("");
  };
  const handleFocusMessage = () => {
    setMessageError("");
  };

  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShow(false);
  };

  const resetForm = () => {
    setFullName("");
    setEmail("");
    setPhoneNo("");
    setSubject("");
    setMessage("");
  };

  const onSuccess = (data) => {
    if (data?.statusCode === 200) {
      setToastType("success");
      setToastMessage(data?.message);
      setShow(true);
      resetForm();
    } else {
      setToastType("danger");
      setToastMessage(data?.message);
    }
  };

  const onError = (error) => {
    setToastType("danger");
    setToastMessage(error?.message);
    setShow(true);
  };

  const { mutate, isLoading } = usePostContactUs(onSuccess, onError);

  const handleSubmitContactUs = (e) => {
    e.preventDefault();

    // Reset errors
    setFullNameError("");
    setEmailError("");
    setPhoneNoError("");
    setSubjectError("");
    setMessageError("");

    let hasError = false;

    if (!FullName) {
      setFullNameError("Please Enter Full Name");
      hasError = true;
    }

    if (!Email) {
      setEmailError("Please Enter Email");
      hasError = true;
    } else if (!Email.includes("@")) {
      setEmailError("Please Enter a valid Email");
      hasError = true;
    }

    if (!PhoneNo) {
      setPhoneNoError("Please Enter Phone Number");
      hasError = true;
    } else if (!/^\d+$/.test(PhoneNo) || parseInt(PhoneNo) <= 0) {
      setPhoneNoError("Please Enter a valid Phone Number");
      hasError = true;
    }

    if (!Subject) {
      setSubjectError("Please Enter Subject");
      hasError = true;
    }

    if (!Message) {
      setMessageError("Please Enter Message");
      hasError = true;
    }

    if (hasError) {
      return;
    }
    var data = {
      fullName: FullName,
      email: Email,
      phoneNo: PhoneNo,
      subject: Subject,
      message: Message,
    };
    mutate(data);
  };

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg fixed-top bg-transparent Navbar-Landing-page ${
          scrolled || innerWidth ? "Scrolled px-3" : "m-3"
        }`}
      >
        <div className="container-fluid px-4">
          <a className="navbar-brand" href="#Home">
            <img
              src={scrolled || innerWidth ? LogoDark : LogoLight}
              alt=""
              srcSet=""
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className={`nav-item ${innerWidth ? "d-flex" : ""}`}>
                <a
                  className={`nav-link position-relative ${
                    innerWidth ? "px-2" : ""
                  } ${activeSection === "Home" ? "active" : ""} `}
                  aria-current="page"
                  href="#Home"
                  onClick={() => handleClick("Home")}
                >
                  Home
                </a>
              </li>
              <li className={`nav-item  ${innerWidth ? "d-flex" : "ms-3"} `}>
                <a
                  className={`nav-link position-relative ${
                    innerWidth ? "px-2" : ""
                  } ${activeSection === "Benefits" ? "active" : ""}`}
                  href="#Benefits"
                  onClick={() => handleClick("Benefits")}
                >
                  Benefits
                </a>
              </li>

              <li className={`nav-item  ${innerWidth ? "d-flex" : "ms-3"} `}>
                <a
                  className={`nav-link position-relative ${
                    innerWidth ? "px-2" : ""
                  } ${activeSection === "Services" ? "active" : ""}`}
                  href="#Services"
                  onClick={() => handleClick("Services")}
                >
                  Services
                </a>
              </li>

              <li className={`nav-item  ${innerWidth ? "d-flex" : "ms-3"} `}>
                <a
                  className={`nav-link position-relative ${
                    innerWidth ? "px-2" : ""
                  } ${activeSection === "HowItWorks" ? "active" : ""}`}
                  href="#HowItWorks"
                  onClick={() => handleClick("HowItWorks")}
                >
                  How It Works
                </a>
              </li>

              <li className={`nav-item  ${innerWidth ? "d-flex" : "ms-3"} `}>
                <a
                  className={`nav-link position-relative ${
                    innerWidth ? "px-2" : ""
                  }  ${activeSection === "ContactUs" ? "active" : ""}`}
                  href="#ContactUs"
                  onClick={() => handleClick("ContactUs")}
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <div>
              <Link to="/OwnerHistory">
                <button className="btn btn-light-contact-submit px-4">
                  Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <section id="Home">
        <div className="Header-Section m-3 rounded-3">
          <div className="text-center text-section ">
            <p className="text-white fs-5">Streamlined Parking Solutions</p>
            <h1 className="text-white fs-48px fw-bold">
              Streamline Your Parking
            </h1>
            <h1 className="text-white fs-48px fw-bold">
              Experience with
              <span className="text-primary fw-bold bold "> ValetXpress </span>
            </h1>
            <p className="t fs-5 text-light-white text-justify-last">
              ValetXpress offers a VIP parking service designed to provide the
              ultimate in individualized service and convenience. Enjoy a
              premium valet parking experience at luxury hotels, high-end
              restaurants, and exclusive events
            </p>
          </div>
        </div>
      </section>
      <section id="Benefits">
        <div className=" container-xxl introductionSection mt-5">
          <div className="text-center mb-5">
            <div className="d-flex gap-1 justify-content-center">
              {[1, 2, 3, 4, 5].map((img) => (
                <img key={img} src={StarIcon} alt="" srcSet="" />
              ))}
            </div>
            <p className="fs-5 text-primary mb-0 mt-2">The ValetXpress Way</p>
            <h2 className="fs-36px mx-sm-5 fw-bold">
              Highlight the professionalism, reliability & efficiency of your
              valet parking service
            </h2>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="text-center ">
                <img className="intro-image" src={introductionImage} alt="" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <p className="fs-18px text-justify">
                ValetXpress delivers a premium valet parking service to high-end
                restaurants, luxury hotels/resorts, and upscale venues. Our
                custom parking solution ensures secure parking, prompt
                retrieval, integrated payment options, while offering
                personalized VIP service to users for a seamless parking
                experience
                <br />
                Our customers offer exceptional convenience and an improved
                valet experience by managing all parking administrative
                operations through the ValetXpress platform. ValetXpress
                prioritizes efficiency & simplicity, allowing patrons to manage
                their parking experience from their smartphone. Feel the
                ultimate in convenience and comfort with our parking management
                system — It's just a tap away! **Book Now and Park with Ease!
              </p>

              <div className="IntroPoints rounded-3 p-3 ">
                <h6 className="fw-bold">ValetXpress Delivers</h6>
                <ul className="mb-0 ">
                  <li> Enhanced convenience & customer satisfaction!</li>
                  <li>Simplified valet parking logistics!</li>
                  <li>Web based parking payment and gratuity!</li>
                  <li>
                    A hassle-free parking experience from arrival to departure!
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Services">
        <div className="  ServicesSection mt-5 py-4 px-md-5 ">
          <div className=" container-xxl   pb-5">
            <div className="d-flex justify-content-center pt-5">
              <div className="text-center text-section">
                <p className="fs-5 fw-bold text-primary mb-0">
                  Elevate Your Parking Experience
                </p>
                <h2 className="fs-36px mt-2 fw-bold">
                  Discover ValetXpress Services
                </h2>
                <p className="fs-18px mt-2 text-justify-last">
                  Explore our tailored valet parking solutions designed to
                  enhance convenience and elevate your parking experience.
                </p>
              </div>
            </div>
            <div className="row g-4 ">
              <div className="col-12 col-sm-6 col-lg-4">
                <div className="position-relative rounded-4 overflow-hidden Services-card ">
                  <div>
                    <img
                      className="card-image "
                      src={PersonalizedCustomerCareImage}
                      alt=""
                    />
                  </div>
                  <div className="box-shadow-cards"></div>
                  <div className="position-absolute card-texts">
                    <h5 className="fs-5 mb-0 text-white mb-2 ">
                      Personalized Customer Care
                    </h5>
                    <p className="mb-0 card-text-color card-desctiption text-justify ">
                      We prioritize the customer experience over all else,
                      ensuring each user interaction with ValetXpress is
                      professional and efficient. Our parking management
                      solutions are designed to handle every vehicle request
                      with care and expediency
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <div className="position-relative rounded-4 overflow-hidden Services-card ">
                  <div>
                    <img
                      className="card-image "
                      src={RealTimeStatusUpdatesImage}
                      alt=""
                    />
                  </div>
                  <div className="box-shadow-cards"></div>
                  <div className="position-absolute card-texts">
                    <h5 className="fs-5 mb-0 text-white mb-2 ">
                      Real-Time Status Updates
                    </h5>
                    <p className="mb-0 card-text-color card-desctiption text-justify">
                      Experience the convenience of real-time notifications with
                      ValetXpress. Your guests simply request their vehicle
                      through our web interface when ready to depart, and your
                      valets can alert them of the vehicle delivery status.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <div className="position-relative rounded-4 overflow-hidden Services-card ">
                  <div>
                    <img
                      className="card-image "
                      src={ConvenienceEfficiencyImage}
                      alt=""
                    />
                  </div>
                  <div className="box-shadow-cards"></div>
                  <div className="position-absolute card-texts">
                    <h5 className="fs-5 mb-0 text-white mb-2 ">
                      Convenience & Efficiency
                    </h5>
                    <p className="mb-0 card-text-color card-desctiption text-justify">
                      Experience the convenience of real-time notifications with
                      ValetXpress. Your guests simply request their vehicle
                      through our web interface when ready to depart, and your
                      valets can alert them of the vehicle delivery status.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <div className="position-relative rounded-4 overflow-hidden Services-card ">
                  <div>
                    <img
                      className="card-image "
                      src={PremiumValetNetworkImage}
                      alt=""
                    />
                  </div>
                  <div className="box-shadow-cards"></div>
                  <div className="position-absolute card-texts">
                    <h5 className="fs-5 mb-0 text-white mb-2 ">
                      Premium Valet Network
                    </h5>
                    <p className="mb-0 card-text-color card-desctiption line-h-1 text-justify">
                      When you become part of our ValetXpess professional
                      network your venue gains access to a network of user
                      profiles and can stand out from your competitors by
                      providing a unique, VIP experience for all guests. Guests
                      enjoy the convenience of fully digital valet parking
                      experience with our full service application at various
                      partner locations including high-end restaurants, luxury
                      hotels/resorts, and exclusive event venues.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <div className="position-relative rounded-4 overflow-hidden Services-card ">
                  <div>
                    <img
                      className="card-image "
                      src={CashlessContactFreeExperienceImage}
                      alt=""
                    />
                  </div>
                  <div className="box-shadow-cards"></div>
                  <div className="position-absolute card-texts">
                    <h5 className="fs-5 mb-0 text-white mb-2 ">
                      Cashless & Contact Free Experience
                    </h5>
                    <p className="mb-0 card-text-color card-desctiption line-h-1 text-justify">
                      When you become part of our ValetXpess professional
                      network your venue gains access to a network of user
                      profiles and can stand out from your competitors by
                      providing a unique, VIP experience for all guests. Guests
                      enjoy the convenience of fully digital valet parking
                      experience with our full service application at various
                      partner locations including high-end restaurants, luxury
                      hotels/resorts, and exclusive event venues.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <div className="position-relative rounded-4 overflow-hidden Services-card ">
                  <div>
                    <img
                      className="card-image "
                      src={EnhancedParkingManagementLogisticsImage}
                      alt=""
                    />
                  </div>
                  <div className="box-shadow-cards"></div>
                  <div className="position-absolute card-texts">
                    <h5 className="fs-5 mb-0 text-white mb-2 ">
                      Enhanced Parking Management Logistics
                    </h5>
                    <p className="mb-0 card-text-color card-desctiption text-justify">
                      Real-time parking information can track users from arrival
                      through departure, making the most of your valet
                      resources. All user information obtained can be used to
                      provide a more personalized experience for guests and
                      allow for targeted exclusive offers for specific VIPs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="HowItWorks">
        <div className=" container-xxl workingxpress mt-5 py-4  ">
          <div className="mx-0 mx-lg-5">
            <div className="d-flex justify-content-center">
              <div className="text-center text-section">
                <p className="fs-5 fw-bold text-primary mb-0">
                  Easy Steps to Seamless Parking
                </p>
                <h2 className="fs-36px mt-2 fw-bold">How ValetXpress Works</h2>
                <p className="fs-18px mt-2 text-justify-last">
                  Discover the simple steps to a premium VIP valet parking
                  experience with ValetXpress. From drop-off to pick-up, our
                  process is designed with you in mind!
                </p>
              </div>
            </div>

            {/* Commented */}
            {/* <div className="row g-3">
              <div className="col-12 col-sm-6 col-lg-4">
                <div className="cards-working p-4 rounded-4 h-100">
                  <img src={QrIcon} alt="" />
                  <h6 className="mt-3 mb-0 fs-5">Arrival & Ticketing</h6>
                  <p className="mb-0 mt-3">
                    Hand over your vehicle to our professional valets and
                    receive a parking ticket. Scan the QR code on your ticket to
                    access our web interface, ensuring a smooth and connected
                    experience from the start.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 position-relative">
                <div className="cards-working animationImage rounded-4 overflow-hidden h-100">
                  <img
                    className="card-image"
                    src={ArrivalTicketingImg}
                    alt=""
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <div className="cards-working p-4 rounded-4 h-100">
                  <img src={RequestVehicleIcon} alt="" />
                  <h6 className="mt-3 mb-0 fs-5">Request Your Vehicle</h6>
                  <p className="mb-0 mt-3">
                    When you're ready to leave, simply request your vehicle via
                    our web page. This ensures that your request is immediately
                    processed, making the retrieval process quick and efficient.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 position-relative">
                <div className="cards-working animationImage rounded-4 overflow-hidden h-100">
                  <img
                    className="card-image"
                    src={ArrivalTicketingImg}
                    alt=""
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-4">
                <div className="cards-working p-4 rounded-4 h-100">
                  <img src={ArrivalTicketingImg} alt="" />
                  <h6 className="mt-3 mb-0 fs-5">
                    Timed Retrieval and Handover
                  </h6>
                  <p className="mb-0 mt-3">
                    Our valets will ensure your vehicle is ready within the
                    specified time frame. Meet our valet at the gate to retrieve
                    your vehicle hassle-free, perfectly timed to your
                    convenience.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 position-relative">
                <div className="cards-working animationImage rounded-4 overflow-hidden h-100">
                  <img
                    className="card-image"
                    src={ArrivalTicketingImg}
                    alt=""
                  />
                </div>
              </div>
            </div> */}

            {/* Cmmented */}
            <Swiper
              spaceBetween={16}
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                700: {
                  slidesPerView: 2,
                  spaceBetween: 16,
                },
                1150: {
                  slidesPerView: 3,
                  spaceBetween: 16,
                },
              }}
              modules={[Autoplay, Navigation, Pagination]}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              className="mySwiper pt-3 pb-5"
            >
              {xpressWorkingCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="position-relative rotate-animation">
                    <div className="position-relative rounded-4 overflow-hidden working-card ">
                      <div>
                        <img className="card-image " src={data.image} alt="" />
                      </div>
                      <div className="box-shadow-cards"></div>
                      <div className="position-absolute card-texts">
                        <h5 className="fs-5 mb-0 text-white mb-2 ">
                          {data.heading}
                        </h5>
                      </div>
                    </div>
                    <div className="col-12 position-absolute cards-working p-3 rounded-4 h-100 rotate-text">
                      <div className="">
                        <img src={data.cardImage} alt="" />
                        <h6 className="mt-2 mb-0 fs-5 line-h-1">
                          {data.cardHeading}
                        </h6>
                        <p className="mb-0 mt-2 text-justify">
                          {data.cardDescription}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      <div className=" parking-experience mt-4">
        <div className="row w-100 m-0">
          <div className="col-md-8 col-12">
            <div className="h-100 d-flex justify-content-center align-items-center">
              <div className="pt-5 px-md-5 py-md-5 ms-lg-5">
                <h5 className="fs-36px text-white">
                  Enhance Your Parking Experience!
                </h5>
                <p className="text-light-white text-justify">
                  Don't wait to experience the convenience and efficiency of
                  ValetXpress! Partner with us and enjoy a VIP valet parking
                  experience with unparalleled quality.
                </p>
                <a href="#ContactUs" className="btn btn-light-contact">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 pe-0">
            <div className="h-100 d-flex justify-content-end align-items-end">
              <img
                className="ParkingExperienceImage ms-5 ps-5"
                src={ParkingExperienceImage}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className=" container-xxl ValetXpressCustomers mt-5 py-4  ">
        <div className="mx-0 mx-md-5">
          <div className="d-flex justify-content-center">
            <div className="text-center text-section">
              <p className="fs-5 fw-bold text-primary mb-0">
                Hear from Our Customers
              </p>
              <h2 className="fs-36px mt-2 fw-bold  ">
                What ValetXpress Customers Are Saying
              </h2>
              <p className="fs-18px mt-2 text-justify-last">
                Discover how ValetXpress is enhancing the valet parking
                experience for our valued customers. Read their testimonials and
                learn why they trust our parking management solution for their
                customers!
              </p>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="cards-working p-4 rounded-4 h-100">
                <p className="mb-0 pb-3 text-justify">
                  ValetXpress made my evening out so much easier. I didn't have
                  to worry about finding a parking spot, and my car was ready
                  exactly when I needed it. Highly recommended!
                </p>
                <div className="customer-details pt-3">
                  <div className="d-flex gap-2">
                    <img height={52} width={52} src={NasaIcon} alt="" />
                    <div className="">
                      <h6 className="fs-5 mb-0">John D</h6>
                      <p className="fs-18px mb-0">CEO</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-4">
              <div className="cards-working p-4 rounded-4 h-100">
                <p className="mb-0 pb-3 text-justify">
                  I was skeptical at first, but ValetXpress exceeded my
                  expectations. The process was simple, and the service was
                  top-notch. My car was returned quickly and safely.
                </p>
                <div className="customer-details pt-3">
                  <div className="d-flex gap-2">
                    <img height={52} width={52} src={DysonIcon} alt="" />
                    <div className="">
                      <h6 className="fs-5 mb-0">Emily R</h6>
                      <p className="fs-18px mb-0">Director</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-4">
              <div className="cards-working p-4 rounded-4 h-100">
                <p className="mb-0 pb-3 text-justify">
                  As someone who attends many events, ValetXpress has been a
                  lifesaver. The convenience and professionalism are unmatched.
                  I can't imagine parking without them now.
                </p>
                <div className="customer-details pt-3">
                  <div className="d-flex gap-2">
                    <img height={52} width={52} src={BelleIcon} alt="" />
                    <div className="">
                      <h6 className="fs-5 mb-0">David L</h6>
                      <p className="fs-18px mb-0">General Manager</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="ContactUs">
        <div className=" ContactUsSection py-5">
          <div className="container-xxl pt-3">
            <div className="d-flex justify-content-center mb-3">
              <div className="text-center text-section">
                <p className="fs-5 fw-bold text-primary mb-0">ValetXpress</p>
                <h2 className="fs-36px mt-2 fw-bold">
                  Premium Valet Parking at Your Fingertips
                </h2>
                <p className="fs-18px mt-2 text-justify-last">
                  *Effortless, Secure, and Personalized Parking Solutions for
                  City Centers, Luxury Hotels, and Upscale Venues.
                </p>
              </div>
            </div>
            <div className="d-flex flex-lg-nowrap flex-wrap  gap-4">
              <div className="contactinfo position-relative p-4 text-center rounded-4 d-flex align-items-center justify-content-center flex-grow-1 gap-5 flex-wrap ">
                <div className="">
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <img src={StripeIconLanding} alt="" srcSet="" />
                    <img src={ApplePayIconLanding} alt="" srcSet="" />
                    <img src={PayPalIconLanding} alt="" srcSet="" />
                  </div>
                  <p className="mb-0 text-light-white mt-2">We accept</p>
                  <h5 className="text-white fw-bold">
                    Stripe, ApplePay & PayPal!
                  </h5>
                </div>

                <div className="contact-us-image mt-4  ">
                  <img src={contactScreenIcon} alt="" srcSet="" />
                  <p className="mb-0 text-light-white mt-2">
                    We integrate with the leading HSM software
                  </p>
                </div>
              </div>

              <div className="ContactForm p-4 rounded-4 flex-grow-1">
                <h6 className=" fs-5 mb-2">Contact Us</h6>
                <h2 className="fs-4 mb-0 text-primary fw-bold">
                  Get Started With Us Today!
                </h2>
                <form onSubmit={handleSubmitContactUs}>
                  <div className="mt-4">
                    <div className="row g-3">
                      <div className="col-12 col-md-6">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Full Name"
                          value={FullName}
                          onChange={(e) => setFullName(e.target.value)}
                          onFocus={handleFocusFullName}
                        />
                        {FullNameError && (
                          <p className="text-primary mb-0 ps-1">
                            {FullNameError}{" "}
                          </p>
                        )}
                      </div>

                      <div className="col-12 col-md-6">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Email Address"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                          onFocus={handleFocusEmail}
                        />
                        {EmailError && (
                          <p className="text-primary mb-0 ps-1">
                            {EmailError}{" "}
                          </p>
                        )}
                      </div>

                      <div className="col-12 col-md-6 PhoneNumberCustomer">
                        <input
                          className="form-control form-control-lg"
                          type="number"
                          placeholder="Phone Number"
                          // min={0}
                          value={PhoneNo}
                          onChange={(e) => setPhoneNo(e.target.value)}
                          onFocus={handleFocusPhoneNo}
                          onWheel={(e) => e.target.blur()}
                        />
                        {PhoneNoError && (
                          <p className="text-primary mb-0 ps-1">
                            {PhoneNoError}{" "}
                          </p>
                        )}
                      </div>

                      <div className="col-12 col-md-6">
                        <input
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Subject"
                          value={Subject}
                          onChange={(e) => setSubject(e.target.value)}
                          onFocus={handleFocusSubject}
                        />
                        {SubjectError && (
                          <p className="text-primary mb-0 ps-1">
                            {SubjectError}{" "}
                          </p>
                        )}
                      </div>
                      <div className="col-12">
                        <textarea
                          className="form-control form-control-lg"
                          rows={5}
                          placeholder="Enter your Message"
                          id="floatingTextarea"
                          value={Message}
                          onChange={(e) => setMessage(e.target.value)}
                          onFocus={handleFocusMessage}
                        ></textarea>
                        {MessageError && (
                          <p className="text-primary mb-0 ps-1">
                            {MessageError}{" "}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="btn btn-light-contact-submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div
                            className="spinner-border spinner-border-sm payment-spinner"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="footer-section">
        <div className="container-xxl">
          <footer className="pt-5">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 mb-3">
                <div className="pe-0 pe-md-5">
                  <img src={FooterLogo} alt="" srcSet="" />

                  <p className="mt-5 pe-0 pe-md-5 text-justify">
                    Premium valet service ensuring convenience and comfort from
                    arrival to departure.
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 mb-3">
                <h5 className="mb-3">Quick Links</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-3">
                    <a
                      href="#Home"
                      className="nav-link p-0 d-flex align-items-center gap-2 "
                    >
                      <img src={FooterLinkIcon} alt="" srcSet="" /> Home
                    </a>
                  </li>
                  <li className="nav-item mb-3">
                    <a
                      href="#Benefits"
                      className="nav-link p-0 d-flex align-items-center gap-2 "
                    >
                      <img src={FooterLinkIcon} alt="" srcSet="" /> Benefits
                    </a>
                  </li>
                  <li className="nav-item mb-3">
                    <a
                      href="#Services"
                      className="nav-link p-0 d-flex align-items-center gap-2 "
                    >
                      <img src={FooterLinkIcon} alt="" srcSet="" /> Services
                    </a>
                  </li>
                  <li className="nav-item mb-3">
                    <a
                      href="#HowItWorks"
                      className="nav-link p-0 d-flex align-items-center gap-2 "
                    >
                      <img src={FooterLinkIcon} alt="" srcSet="" /> How It Works
                    </a>
                  </li>
                  <li className="nav-item mb-3">
                    <a
                      href="#ContactUs"
                      className="nav-link p-0 d-flex align-items-center gap-2 "
                    >
                      <img src={FooterLinkIcon} alt="" srcSet="" /> Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-sm-6 col-md-4 mb-3">
                <h5 className="mb-3">Contact Us</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-3">
                    <a
                      href="mailto:admin@valetxpress.com"
                      className="text-decoration-none"
                    >
                      <div className="nav-link p-0 d-flex align-items-center gap-2 ">
                        <img src={MessageIconFooter} alt="" srcSet="" />
                        contact@valetxpress.io
                      </div>
                    </a>
                  </li>
                </ul>
                <ul className="list-unstyled d-flex mb-0 mt-4">
                  <li className="">
                    <a className="link-body-emphasis" href="/">
                      <YoutubeIconFooter />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a className="link-body-emphasis" href="/">
                      <InstagramIconFooter />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a className="link-body-emphasis" href="/">
                      <FacebookIconFooter />
                    </a>
                  </li>
                  <li className="ms-3">
                    <a className="link-body-emphasis" href="/">
                      <TwitterIconFooter />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center  py-3 mt-1 border-top">
              <p className="mb-4 mb-sm-0">
                Copy right @ 2024 - All rights reserved
              </p>
            </div>
          </footer>
        </div>
      </div>
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ zIndex: 9999, position: "fixed", top: "1rem", right: "1rem" }}
      >
        <Toast
          bg={toastType}
          onClose={handleClose}
          show={show}
          className="fw-semibold text-white"
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default LandingPage;

const xpressWorkingCards = [
  {
    image: ArrivalTicketingImg,
    heading: "Premium Valet Parking",
    cardImage: QrIcon,
    cardHeading: "Arrival & Ticketing",
    cardDescription:
      "ValetXpress is designed to work seamlessly with existing valet processes. Scanning the QR code on the ticket gives access to the valet location information from anywhere, ensuring a smooth and connected experience from the start for the user, attendant and admins.",
  },
  {
    image: ContactlessVehicleRetrievalImage,
    heading: "Contactless Vehicle Retrieval",
    cardImage: RequestVehicleIcon,
    cardHeading: "Contactless Vehicle Retrieval",
    cardDescription:
      "When the user is ready to leave, they can simply request their vehicle via their smartphone. The request is immediately sent to the valet attendant managing the vehicle, streamlining the vehicle retrieval process",
  },
  {
    image: ElectronicPaymentsImage,
    heading: "Electronic Payments",
    cardImage: RequestVehicleIcon,
    cardHeading: "Electronic Payments",
    cardDescription:
      "ValetXpress allows for electronic payment from any mobile device for valet services with options to manage gratuity for specific attendants, providing a truly cashless experience for guests. Payment is immediately calculated and can be processed via credit card, ApplePay, or PayPal and ValetXpress integrates with most popular POS/HMS systems.",
  },
  {
    image: RealTimeUpdatesImage,
    heading: "Real-Time Updates",
    cardImage: BellIcon,
    cardHeading: "Real-Time Updates",
    cardDescription:
      "Rather than waiting in the dark, the user will receive notifications about their vehicle’s status and will be alerted when it is ready for pickup at the venue’s valet pick-up/drop-off location. Your guests can perfectly time their departure to meet their vehicle for a hassle-free, VIP valet experience for all users.",
  },
];
