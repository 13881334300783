import Modal from "react-bootstrap/Modal";
import {  useCountryCodesGetAll } from "../../hooks/common";
export const VerticallyCenteredModal = ({
  type = "success",
  title,
  subtitle,
  show,
  closeModal,
  actionName,
  handleAction,
  inputValue,
  inputOnChange,
  SelectValue,
  SelectOnChange,
}) => {
  const { data, isLoading: CountryCodesLoading } = useCountryCodesGetAll();
  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={closeModal}
      >
        <div className="modal-header">
        <h5 className="modal-title h5">Update Phone Number</h5>
          <button
            type="button"
            className="btn-close small"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <Modal.Body>
            <div className="">
              <div className="col-12">
                <label className=" small  mb-2">Phone Number</label>
                <div className="d-flex align-items-center bg-white px-2 rounded-2 mb-2 svg-input">
                  <img
                    src="/static/media/input-mobile-svg.845488e21b4488ab765d4d3142d98824.svg"
                    alt=""
                    srcSet=""
                  />
                  <div className="d-flex">
                    <select
                      className="p-2 border-0  text-dark outline-0 form-control"
                      style={{ maxWidth: "30%" }}
                      value={SelectValue}
                      onChange={SelectOnChange}
                    >
                         {data?.map((options)=>(
                    <option key={options.id} value={options.id}>{options.code}({options.country})</option>
                  ))}
              
                    </select>
                    <input
                      type="number"
                      placeholder="Please enter"
                      className="flex-grow-1 p-2 border-0 outline-0 text-dark form-control"
                      value={inputValue}
                      onChange={inputOnChange}
                      style={{ maxWidth: "70%" }}
                    />
                  </div>
                </div>
              </div>
              <h6 className="semibold">{title}</h6>
              <p className="">{subtitle}</p>
              <div className="d-flex justify-content-end gap-3 mb-2">
                {actionName ? (
                  <button
                    type="button"
                    className="btn rounded-4 px-3  btn-primary text-uppercase"
                    // data-bs-toggle="modal"
                    // data-bs-target="#successfullmessage"
                    onClick={() => {
                      handleAction && handleAction();
                      closeModal();
                    }}
                  >
                    {actionName}
                  </button>
                ) : null}
                <button
                  type="button"
                  className="btn btn-close-modal px-3 rounded-5"
                  onClick={closeModal}
                >
                  CLOSE
                </button>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerticallyCenteredModal;
