import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import main_image from "../../../assets/imgs/login.png";
import Logo from "../../../Logo";
export const OtpVerification = ({
  otp,
  setOtp,
  otpSubmit,
  otpMessage,
  otpResend,
  handleFocus,
}) => {
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Decrease the timer by 1 second
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clear the interval when the timer reaches 0
    if (seconds === 0) {
      clearInterval(intervalId);
    }

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [seconds]);

  const handelResendOtp = () => {
    setSeconds(30);
    otpResend();
  };

  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        {/* <!-- *********=========COL CARDS=========*********  --> */}
        <div className="col-lg-7  p-4 p-md-5 ">
          <div className="mb-sm-5 mt-sm-3">
            <Logo />
          </div>

          <h1 className="bold text-primary pt-sm-5 pb-sm-2">
            OTP Verification
          </h1>
          <h5 className="mb-sm-5">
            We send you and OTP on your mobile number, enter OTP for
            verification
          </h5>
          <form onSubmit={otpSubmit}>
            <div className="col-sm-6 ">
              <div className="d-flex flex-column lign-items-center gap-3 px-2 rounded-2 mb-3 OtpNum">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  inputType="number"
                  numInputs={4}
                  renderInput={(props) => (
                    <input
                      type="number"
                      {...props}
                      onFocus={handleFocus}
                      style={{
                        border: "1px solid $gray",
                        backgroundColor: "#f9f9f9",
                      }}
                      className={`flex-grow-1 p-3 outline-0  text-dark form-control rounded-4  text-center me-3 ${
                        otpMessage === "Invalid OTP"
                          ? "OtpinputFeildInvalid"
                          : "OtpinputFeild"
                      }`}
                    />
                  )}
                />
                {otpMessage === "Invalid OTP" && (
                  <p className="text-primary text-end pe-4">Invalid OTP</p>
                )}
              </div>
              {seconds > 0 && (
                <p className="text-primary">Resend OTP on {seconds} second.</p>
              )}
            </div>
            {/* <!-- *********========= MODAL LONCH BUTTON =========*********  --> */}
            <div className="d-flex">
              <button
                type="submit"
                className={`btn rounded-4 px-5 mt-5 me-3 medium btn-primary submit-button `}
              >
                CONTINUE
                {/* {isLoading ? <Spinner /> : "CONTINUE"} */}
              </button>

              {seconds > 0 ? (
                ""
              ) : (
                <button
                  type="button"
                  className={`btn rounded-4 px-5 mt-5 medium btn-primary submit-button`}
                  onClick={handelResendOtp}
                >
                  Resend OTP
                </button>
              )}
            </div>
          </form>
        </div>
        {/* <!-- *********=========COL SATRTED=========*********  --> */}
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
