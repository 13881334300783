import { fetchWrapper } from "./restApi";
const API_URL_GET = process.env.REACT_APP_API_URL;

export const getTicketByID = (ticketId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/TicketGetById?ticketId=${ticketId}`
  );
};

export const updateVehicleNumber = (ticketId, vehicleNumber) => {
  return fetchWrapper("PUT", `${API_URL_GET}/UpdateVehicleNumber`, {
    id: ticketId,
    vehicleNumber: vehicleNumber,
  });
};

export const requestVehicle = (ticketId) => {
  return fetchWrapper("PUT", `${API_URL_GET}/VehicleReceivingRequest`, {
    id: ticketId,
  });
};

export const getVehicleDeliveryTimeSlots = () => {
  return fetchWrapper("GET", `${API_URL_GET}/VehicleDeliveryTimeSlots`);
};

export const deliverVehicle = (ticketId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/DeliverVehicle?ticketId=${ticketId}`
  );
};

export const setVehicleDeliveryTime = (ticketId, time) => {
  return fetchWrapper("PUT", `${API_URL_GET}/SetVehicleDeliveryTimeInternal`, {
    id: ticketId,
    deliveryTimeInMinutes: time,
  });
};

export const setFeedback = (ticketId, stars, feedback) => {
  return fetchWrapper("POST", `${API_URL_GET}/FeedbackCreate`, {
    parkingTicketId: ticketId,
    stars: stars,
    feedback: feedback,
  });
};

export const reparkVehicle = (ticketId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/ReparkVehicle/?ticketId=${ticketId}`
  );
};

export const getEasternTime = () => {
  return fetchWrapper("GET", `${API_URL_GET}/GetCurrentDateTime`);
};
export const getParkingLocationGetAttendents = (ticketId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/ParkingLocationGetAttendents?ticketId=${ticketId}`
  );
};
export const PutUpdateAttendent = (ticketId, updatedAttendentId) => {
  return fetchWrapper("PUT", `${API_URL_GET}/ParkingLocationUpdateAttendent`, {
    id: ticketId,
    attendantId: updatedAttendentId,
  });
};
export const getCountryCodesGetAll = () => {
  return fetchWrapper("GET", `${API_URL_GET}/CountryCodesGetAll`);
};
export const updateOwnerPhoneNumber = (
  ticketId,
  phoneNumber,
  countryCodeId
) => {
  return fetchWrapper("PUT", `${API_URL_GET}/UpdateOwnerPhoneNumber`, {
    id: ticketId,
    phoneNumber: phoneNumber,
    countryCodeId: countryCodeId,
  });
};
export const getIsOwnerPhoneNumberExist = (countryCodeId, phoneNumber) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/IsOwnerPhoneNumberExist?PhoneNumber=${phoneNumber}&CountryCodeId=${countryCodeId}`
  );
};
export const getTicketsByOwnerPhoneNumber = (
  page,
  rowPerPage,
  countryCodeId,
  phoneNumber
) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/GetTicketsByOwnerPhoneNumber?page=${page}&size=${rowPerPage}&ownerPhoneNumber=${phoneNumber}&countryCodeId=${countryCodeId}`
  );
};
export const getOTPVerification = (phoneNumber, otp, countryCodeId) => {
  // return fetchWrapper("GET", `${API_URL_GET}/OTPVerification?PhoneNumber=3335420271&OTPCode=5572&CountryCodeId=3`);
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/OTPVerification?PhoneNumber=${phoneNumber}&OTPCode=${otp}&CountryCodeId=${countryCodeId}`
  );
};

export const postProcessPayment = (data) => {
  return fetchWrapper("POST", `${API_URL_GET}/process-payment`, data);
};

export const getTicketBill = (ticketId) => {
  return fetchWrapper("GET", `${API_URL_GET}/TicketBill?ticketId=${ticketId}`);
};

export const postContactUs = (data) => {
  return fetchWrapper("POST", `${API_URL_GET}/ContactUs`, data);
};

export const getPaymentMethodsGetAll = (phoneNumber) => {
  return fetchWrapper("POST", `${API_URL_GET}/PaymentMethodsGetByPhoneNo`, {
    phoneNumber: phoneNumber,
  });
};

export const postSetupIntentCreate = (phoneNumber) => {
  return fetchWrapper(
    "POST",
    `${API_URL_GET}/SetupIntentCreate`,
    phoneNumber
  );
};

export const postPaymentMethodCreate = (data) => {
  return fetchWrapper(
    "POST",
    `${API_URL_GET}/PaymentMethodCreate`,
    data
  );
};
export const postDefautPaymentMethod = (data) => {
  return fetchWrapper(
    "POST",
    `${API_URL_GET}/DefaultAccount`,
    data
  );
};
export const postPaymentCheckout = (data) => {
  return fetchWrapper("POST", `${API_URL_GET}/create-payment-intent`, data);
};
export const postPaymentMethodDelete = (id) => {
  return fetchWrapper("POST", `${API_URL_GET}/PaymentMethodDelete?PaymentMethodId=${id}`);
};

export const postPaymentMethodCreationOrSettingDefault = (id) => {
  return fetchWrapper("POST", `${API_URL_GET}/PaymentMethodCreationOrSettingDefault?ticketId=${id}`);
};