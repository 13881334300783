import React, { useState } from "react";
import Logo from "../../Logo";
import main_image from "../../assets/imgs/main-img.png";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import mobile_svg from "../../assets/imgs/icons/mobile-svg.svg";
import contect_svg from "../../assets/imgs/icons/contect-svg.svg";
import car_svg from "../../assets/imgs/icons/car-svg.svg";
import calender_svg from "../../assets/imgs/icons/calender-svg.svg";
import UpdateAttendentModal from "../common/UpdateAttendentModal";
import { ReactComponent as EditIcon } from "../../assets/imgs/icons/edit-pen.svg";
import {
  useVehicleDeliverySlots,
  useSetVehicleDeliveryTime,
  useParkingLocationGetAttendents,
  useSetUpdateAttendent,
} from "../../hooks/common";
import { useParams } from "react-router";
import CustomTimeModal from "./CustomTimer";
import Spinner from "../common/spinner/spinner";
import { getMonth } from "../../utils/getMonth";
import Loading from "../common/Loader/Loading";
const RecivingTimer = ({
  ticketId,
  ownerContact,
  countryCode,
  attendentName,
  vehicleNumber,
  parkingTime,
  status,
}) => {
  const [ShowUpdateAttendent, setShowUpdateAttendent] = useState(false);
  const [UpdateAttendent, setUpdateAttendent] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { ticketId: ticketIdBackend } = useParams();
  const { data, isLoading: getDeliverySlots } = useVehicleDeliverySlots();
  const { data: AttedentNames } =
    useParkingLocationGetAttendents(ticketIdBackend);
  const handleSuccess = () => {};
  const { mutate, isLoading } = useSetVehicleDeliveryTime(handleSuccess);
  const { mutate: UpdateAttendentName, isLoading: UpdatingAttendentLoading } =
    useSetUpdateAttendent();
  const [selectedTime, setSelectedTime] = useState("2");
  const handleSubmit = () => {
    mutate({
      ticketIdBackend,
      time: parseInt(selectedTime, 10),
    });
  };
  const toggleModalUpdateAttendent = () => {
    setShowUpdateAttendent((prevState) => {
      return !prevState;
    });
  };
  const handleUpdateAttendent = () => {
    const data = {
      id: ticketIdBackend,
      updatedAttendentId: UpdateAttendent,
    };
    UpdateAttendentName(data);
  };
  const handleSaveCustomTime = () => {
    mutate({
      ticketIdBackend,
      time: parseInt(selectedTime, 10),
    });
    toggeleModal();
  };
  const toggeleModal = () => {
    setSelectedTime();
    setShowModal(!showModal);
  };
  if (isLoading || getDeliverySlots || UpdatingAttendentLoading) {
    return <Loading />;
  }
  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        {/* <!-- *********=========COL- STARTED =========*********  --> */}
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        {/* <!-- *********=========COL- CARDS =========*********  --> */}
        <div className="col-lg-7 side-card p-4 p-md-5">
        

          {status === "Waiting" ? (
            <h4 className="bold ">
              Vehicle Owner <span className="text-primary bold">Request</span>
            </h4>
          ) : (
            <h4 className="bold ">
              Owner has{" "}
              <span className="text-primary bold"> Not Requested </span> Vehicle
            </h4>
          )}
          {status === "Waiting" ? (
            <>
              <p>
                Please select an estimated delivery time. We will alert the
                owner that their request has been received:
              </p>
              <div>
                <div className="d-flex gap-3 flex-wrap">
                  {data?.map((time, index) => {
                    if (selectedTime === time) {
                      return (
                        <button
                          key={index}
                          className="btn rounded-3 px-3 py-1 active medium btn-light-primary"
                          onClick={() => {
                            setSelectedTime(time);
                          }}
                        >
                          {`${time} mins`}
                        </button>
                      );
                    } else {
                      return (
                        <button
                          key={index}
                          className="btn rounded-3 px-3 py-1 medium btn-light-primary"
                          onClick={() => {
                            setSelectedTime(time);
                          }}
                        >
                          {`${time} mins`}
                        </button>
                      );
                    }
                  })}
                  {/* <!-- *********========= MODAL LONCH BUTTON =========*********  --> */}
                  <button
                    className="btn rounded-3 px-3 py-1 medium btn-light-custom text-uppercase"
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    onClick={toggeleModal}
                  >
                    custom
                  </button>
                </div>

                {/* <!-- *********========= Modal =========*********  --> */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content"></div>
                  </div>
                </div>
              </div>
              {/* <!-- Button trigger modal --> */}
              <div>
                <button
                  type="button"
                  className="btn rounded-4 px-5 mt-4 medium btn-primary submit-button text-uppercase"
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {isLoading ? <Spinner /> : "Submit"}
                </button>
              </div>
            </>
          ) : null}



          <div className="pt-4 pt-md-5">
          <h4 className="bold mb-3">
            <span className="text-primary bold">Ticket</span> Details
          </h4>
          <div className="gray-bg details-section p-0 p-md-3 rounded-3">
            <div className="row g-2">
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={ticket_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Parking Ticket </p>
                </div>
                <p className="semibold properties ms-4 mb-0">{ticketId}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={mobile_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Owner Contact</p>
                </div>
                <p className="semibold properties ms-4 mb-0">
                  {countryCode} {ownerContact}
                </p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={contect_svg} alt="" srcSet="" />
                  <p className="m-0 w-100 d-flex align-items-center">
                    Attendant Name
                    <button
                      className="ms-2 btn btn-light-success d-flex justify-content-center align-items-center border-0 px-2"
                      type="button"
                      // data-bs-toggle="modal"
                      onClick={() => {
                        toggleModalUpdateAttendent();
                      }}
                    >
                      <EditIcon />
                    </button>
                  </p>
                </div>
                <p className="semibold properties ms-4 mb-0">{attendentName}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={car_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Vehicle License Plate #</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{vehicleNumber}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={calender_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Parked at</p>
                </div>
                <p className="semibold properties ms-4 mb-0">
                  {`${getMonth(
                    String(new Date(parkingTime).getMonth())
                  )} ${String(new Date(parkingTime).getDate()).padStart(
                    2,
                    "0"
                  )}, ${new Date(parkingTime).getFullYear()} ${String(
                    new Date(parkingTime).getHours()
                  ).padStart(2, "0")}:${String(
                    new Date(parkingTime).getMinutes()
                  ).padStart(2, "0")}`}
                </p>
              </div>
            </div>
          </div>
          </div>


        </div>
      </div>
      <CustomTimeModal
        show={showModal}
        closeModal={toggeleModal}
        handleSave={handleSaveCustomTime}
        inputTime={selectedTime}
        inputTimeOnchange={(e) => {
          setSelectedTime(e.target.value);
        }}
      />
      <UpdateAttendentModal
        show={ShowUpdateAttendent}
        handleAction={handleUpdateAttendent}
        closeModal={toggleModalUpdateAttendent}
        type="error"
        actionName="Update"
        disabled={UpdateAttendent > 0 ? false : true}
        SelectOptions={AttedentNames}
        SelectValue={UpdateAttendent}
        SelectOnChange={(e) => {
          setUpdateAttendent(e.target.value);
        }}
      />
    </div>
  );
};
export default RecivingTimer;
