import React, { useEffect, useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Logo from "../../Logo";
import main_image from "../../assets/imgs/main-img.png";
import StripeIcon from "../../assets/imgs/icons/StripeIconLanding.png";
import stripepay from "../../assets/imgs/icons/stripepay.png";
import Appalpay from "../../assets/imgs/icons/Appalpay.png";
import { ReactComponent as DeleteIcon } from "../../assets/imgs/icons/DeleteIcon.svg";
import {
  usePaymentMethodCreate,
  usePaymentMethodsGetAll,
  usePostDefautPaymentMethod,
  usePostPaymentMethodDelete,
  useSetupIntentCreate,
} from "../../hooks/common";
import { useParams } from "react-router";
import SuccessPaymentModal from "../common/Modals/SuccessPaymentModal";
import ErrorPaymentModal from "../common/Modals/ErrorPaymentModal";
import Loading from "../common/Loader/Loading";
import DeletePaymentMethodModel from "../common/Modals/DeletePaymentMethodModel";
import { Link } from "react-router-dom";
const PaymentMethodSelect = ({
  countryCode,
  ownerContact,
  isPaymentMethodCreatedOrSelected,
}) => {
  const { ticketId: ticketIdBackend } = useParams();

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardExpiryError, setCardExpiryError] = useState("");
  const [cardCvcError, setCardCvcError] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [activeCardId, setActiveCardId] = useState(null);
  const [cardComplete, setCardComplete] = useState({
    number: false,
    expiry: false,
    cvc: false,
  });

  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState(false);

  const handleDeleteModelOpen = (id) => {
    setShowDeleteModel(true);
    setPaymentMethodId(id);
  };
  const handleDeleteModelClose = () => {
    setShowDeleteModel(false);
  };

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Stripe");

  const handlePaymentMethodClick = (method) => {
    setSelectedPaymentMethod(method);
  };

  const onSuccess = (data) => {
    setClientSecret(data.clientSecret);
    setCustomerId(data.customerId);
  };
  const onSuccessDefault = (data) => {};
  const onSuccessDelete = (data) => {
    if (data.statusCode == 200) {
    } else {
      setError(data.message);
    }
    setShowDeleteModel(false);
  };
  const onErrorDelete = (error) => {
    setError(error.message);
    setShowDeleteModel(false);
  };
  const onSuccessAdd = (data) => {};
  const { data: PaymentMethods, isLoading: isGettingPaymentMethods } = usePaymentMethodsGetAll(countryCode + "" + ownerContact);
  const { isLoading: isSetupIntentCreate } = useSetupIntentCreate(
    {
      phoneNumber: countryCode + "" + ownerContact,
    },
    onSuccess
  );
  const { mutate, isLoading } = usePaymentMethodCreate(onSuccessAdd);
  const { mutate: DeletePaymentMethod, isLoading: isDeletingPaymentMethod } = usePostPaymentMethodDelete(onSuccessDelete, onErrorDelete);
  const { mutate: DefautPaymentMethod, isLoading: isDefaultLoading } = usePostDefautPaymentMethod(onSuccessDefault);

  const handleCardClick = (id) => {
    setActiveCardId(id);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (activeCardId) {
      var data = {
        paymentMethodId: activeCardId,
        ticketId: ticketIdBackend,
      };
      DefautPaymentMethod(data);
    } else {
      if (!name) {
        setNameError("Name is required");
        setLoading(false);
        return;
      }

      if (!email) {
        setEmailError("Email is required");
        setLoading(false);
        return;
      }

      if (!cardComplete.number) {
        setCardNumberError("Card number is required");
        setLoading(false);
        return;
      }

      if (!cardComplete.expiry) {
        setCardExpiryError("Card expiry date is required");
        setLoading(false);
        return;
      }

      if (!cardComplete.cvc) {
        setCardCvcError("Card CVC is required");
        setLoading(false);
        return;
      }

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: name,
            email: email,
          },
        },
      });
      console.log("result", result)
      if (result.error) {
        alert(result.error.message);
      } else {
        var data = {
          customerId: customerId,
          paymentMethodId: result.setupIntent.payment_method,
          name: name,
          email: email,
          ticketId: ticketIdBackend,
        };
        mutate(data);
        // Store paymentMethodId in your database
        // await fetch("http://192.168.18.101:90/PaymentMethodCreate", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({

        //   }),
        // });
      }
    }
    setLoading(false);
  };

  const handleDelete = () => {
    DeletePaymentMethod(paymentMethodId);
  };

  const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        color: "#32325d",
        "::placeholder": { color: "#aab7c4" },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    showIcon: true,
  };


  if (
    isSetupIntentCreate ||
    isDeletingPaymentMethod
    // || isGettingPaymentMethods
  ) {
    return <Loading />;
  }

  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7 side-card p-4 p-md-5 justify-content-center align-items-center">
          <div className="mb-3">
            <h4 className="bold ">
              Select <span className="h4 text-primary bold"> Payment </span>
              Method
            </h4>
            <p>
              Please select your preferred payment method and enter your payment
              information to continue. By selecting "continue", you are agreeing
              to ValetXpress's Payment Terms of Service. <Link className="text-primary" to="https://valetxpress.io/doc/ValetXpress%20Payment%20Terms%20of%20Service.pdf" target="_blank"> Click here</Link>  to review
              ValetXpress Payment Terms of Service.
            </p>
          </div>

          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
            {/* <div className="mb-2 me-2">
              <h5 className="mb-0">How would you like to pay</h5>
            </div> */}

            <div className="d-flex gap-3 flex-wrap mt-3">
              <button
                className={`btn btn-lg rounded-3 px-3 py-1 btn-light-paymentType d-flex align-items-center gap-1  ${
                  selectedPaymentMethod === "Stripe" ? "active" : ""
                }`}
                onClick={() => handlePaymentMethodClick("Stripe")}
              >
                <img src={stripepay} />
                Stripe
              </button>
              {/* <button
                className={`btn btn-lg rounded-3 px-3 py-1 btn-light-paymentType d-flex align-items-center gap-1  ${
                  selectedPaymentMethod === "Apple Pay" ? "active" : ""
                }`}
                onClick={() => handlePaymentMethodClick("Apple Pay")}
              >
                <img src={Appalpay} />
                Apple Pay
              </button> */}
            </div>
          </div>

          <div className="d-flex align-items-center gap-3 flex-wrap pb-2">
            {PaymentMethods?.map((data) => (
              <div
                className={`d-flex gap-4 StripeSavedCard p-3 rounded-4 ${
                  activeCardId === data.stripePaymentMethodId ? "active" : ""
                }`}
                key={data.stripePaymentMethodId}
                onClick={() => handleCardClick(data.stripePaymentMethodId)}
              >
                <div className="d-flex gap-2 align-items-center  ">
                  <div className="">
                    <img src={StripeIcon} alt="" height={45} width={45} />
                  </div>
                  <div className="">
                    <p className="mb-0 fw-bold">{data.name}</p>
                    <p className="mb-0 gray-text">
                      **** **** **** {data.last4Digits}
                    </p>
                  </div>
                </div>
                <div className="">
                  <button
                    className="ms-2 btn btn-light-danger d-flex justify-content-center align-items-center border-0  p-2 "
                    type="button"
                    onClick={() => handleDeleteModelOpen(data.id)}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
          {PaymentMethods?.length > 0 && (
            <div className="d-flex align-items-center gap-3 flex-wrap my-4">
              <div className="flex-grow-1">
                <hr />
              </div>
              <p className="mb-0 fs-6">Or add a new Payment card</p>
              <div className="flex-grow-1">
                <hr />
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="StripeComponents row g-3 pt-2">
              <div className="col-12 col-md-6">
                <input
                  id="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (nameError) {
                      setNameError("");
                    }
                  }}
                  type="text"
                  placeholder="Name on card"
                  // required
                />
                {nameError && <div className="text-danger">{nameError}</div>}
              </div>

              <div className="col-12 col-md-6">
                <input
                  id="email"
                  type="email"
                  placeholder="Your Email"
                  // required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (emailError) {
                      setEmailError("");
                    }
                  }}
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </div>
              <div className="col-12 col-md-6">
                <CardNumberElement
                  id="card-number"
                  options={ELEMENT_OPTIONS}
                  onChange={(e) => {
                    setCardNumberError(e.error ? e.error.message : "");
                    setCardComplete((prevState) => ({
                      ...prevState,
                      number: e.complete,
                    }));
                  }}
                />
                {cardNumberError && (
                  <div className="text-danger">{cardNumberError}</div>
                )}
              </div>
              <div className="col-12 col-md-6">
                <CardExpiryElement
                  id="card-expiry"
                  options={ELEMENT_OPTIONS}
                  onChange={(e) => {
                    setCardExpiryError(e.error ? e.error.message : "");
                    setCardComplete((prevState) => ({
                      ...prevState,
                      expiry: e.complete,
                    }));
                  }}
                />
                {cardExpiryError && (
                  <div className="text-danger">{cardExpiryError}</div>
                )}
              </div>
              <div className="col-12 col-md-6">
                <CardCvcElement
                  id="card-cvc"
                  options={ELEMENT_OPTIONS}
                  onChange={(e) => {
                    setCardCvcError(e.error ? e.error.message : "");
                    setCardComplete((prevState) => ({
                      ...prevState,
                      cvc: e.complete,
                    }));
                  }}
                />
                {cardCvcError && (
                  <div className="text-danger">{cardCvcError}</div>
                )}
              </div>
            </div>
            {error && <div className="text-danger pt-2">{error}</div>}
            <div className="mt-4">
              <div>
                <button
                  type="submit"
                  className="btn btn-primary px-4 rounded-5 text-uppercase"
                  disabled={loading}
                >
                  {loading || isLoading || isDefaultLoading ? (
                    <div
                      className="spinner-border spinner-border-sm payment-spinner"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "continue"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <DeletePaymentMethodModel
        show={showDeleteModel}
        closeModal={handleDeleteModelClose}
        handleDelete={handleDelete}
      />
    </div>
  );
};
export default PaymentMethodSelect;
