import React, { useEffect, useState } from "react";
import Logo from "../../Logo";
import { useParams, useNavigate } from "react-router";
import main_image from "../../assets/imgs/main-img.png";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import mobile_svg from "../../assets/imgs/icons/mobile-svg.svg";
import contect_svg from "../../assets/imgs/icons/contect-svg.svg";
import car_svg from "../../assets/imgs/icons/car-svg.svg";
import calender_svg from "../../assets/imgs/icons/calender-svg.svg";
import { ReactComponent as EditIcon } from "../../assets/imgs/icons/edit-pen.svg";
import {
  useDeliverVehicle,
  useReparkVehicle,
  useEasternTime,
} from "../../hooks/common";
import Timer from "../common/Timer";
import { getMonth } from "../../utils/getMonth";
import VerticallyCenteredModal from "../common/Modal";
import Loading from "../common/Loader/Loading";

const TimeStatus = ({
  ticketId,
  ownerContact,
  countryCode,
  attendentName,
  vehicleNumber,
  parkingTime,
  startInterval,
  time,
  isWaiting,
}) => {
  const navigate = useNavigate();
  const { ticketId: ticketIdBackend } = useParams();
  const [show, setShow] = useState(false);
  const handleSuccess = ()=>{}
  const { mutate, isLoading } = useDeliverVehicle(handleSuccess);
  const { mutate: reparkVehicle, isLoading: getReparkVehicle } = useReparkVehicle(handleSuccess);
  const { data: EasternTime, isLoading: getEasternTime } = useEasternTime();
  const handleDeliver = () => {
    mutate(ticketIdBackend);
  };
  const toggleModal = () => {
    setShow((prevState) => {
      return !prevState;
    });
  };
  const handleTimerEnd = () => {
    reparkVehicle(ticketIdBackend);
  };

  function calculateTimeDifference(date1, date2) {
    const oneSecond = 1000; // Number of milliseconds in one second
    const oneMinute = oneSecond * 60; // Number of milliseconds in one minute
    const oneHour = oneMinute * 60; // Number of milliseconds in one hour
    const oneDay = oneHour * 24; // Number of milliseconds in one day

    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    // Calculate the difference in milliseconds
    const diffMilliseconds = Math.abs(firstDate - secondDate);

    // Calculate the difference in days, hours, minutes, and seconds
    const days = Math.floor(diffMilliseconds / oneDay);
    const hours = Math.floor((diffMilliseconds % oneDay) / oneHour);
    const minutes = Math.floor((diffMilliseconds % oneHour) / oneMinute);
    const seconds = Math.floor((diffMilliseconds % oneMinute) / oneSecond);

    return { days, hours, minutes, seconds };
  }
  const handleCalculateTimeRemaining = () => {
    const { days, hours, minutes, seconds } = calculateTimeDifference(
      EasternTime,
      startInterval
    );
    if (days === 0 && hours === 0) {
      let timePassed = minutes * 60 + seconds;
      let selectedIntervalInSeconds = time * 60;
      if (selectedIntervalInSeconds > timePassed) {
        return selectedIntervalInSeconds - timePassed;
      } else {
        return 0;
      }
    } else return 0;
  };
if(isLoading || getReparkVehicle || getEasternTime){
  return <Loading/>;
}
  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        {/* <!-- *********=========COL- STARTED =========*********  --> */}
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        {/* <!-- *********=========COL- CARDS =========*********  --> */}
        <div className="col-lg-7 side-card p-4 p-md-5">
         
          <h4 className="bold ">
            <span className="text-primary bold">Pending Request </span> for Ticket {ticketId} 
          </h4>
          <p className="mobile-text">
          Tap “DELIVERED” once the vehicle has been returned to its owner.
          </p>
          <div className="d-flex align-items-baseline">
            {EasternTime ? (
              <Timer
                initialTime={parseInt(handleCalculateTimeRemaining())}
                onTimerEnd={handleTimerEnd}
              />
            ) : null}
            
            <p className="mx-2 "> Minutes Remaining  </p>
          </div>
          <div>
            <button
              type="button"
              className="btn rounded-4 px-5 mt-2 medium btn-primary submit-button text-uppercase"
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              onClick={toggleModal}
            >
              Delivered
            </button>
          </div>

          <div className="pt-4 pt-md-5">
          <h4 className="bold mb-3">
            <span className="text-primary bold">Ticket</span> Details
          </h4>
          <div className="gray-bg details-section p-0 p-md-3 rounded-3 ">
            <div className="row g-2">
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={ticket_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Parking Ticket</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{ticketId}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={mobile_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Owner Contact</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{countryCode} {ownerContact}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={contect_svg} alt="" srcSet="" />
                  <p className="m-0  w-100">Attendant Name
               
                  </p>
                </div>
                <p className="semibold properties ms-4 mb-0">{attendentName}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={car_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Vehicle License Plate #</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{vehicleNumber}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={calender_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Parked at</p>
                </div>
                <p className="semibold properties ms-4 mb-0">
                  {`${getMonth(
                    String(new Date(parkingTime).getMonth())
                  )} ${String(new Date(parkingTime).getDate()).padStart(
                    2,
                    "0"
                  )}, ${new Date(parkingTime).getFullYear()} ${String(
                    new Date(parkingTime).getHours()
                  ).padStart(2, "0")}:${String(
                    new Date(parkingTime).getMinutes()
                  ).padStart(2, "0")}`}
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <VerticallyCenteredModal
        show={show}
        closeModal={toggleModal}
        type="error"
        resetAction
        handleReset={handleTimerEnd}
        actionName="CHECKOUT"
        handleAction={handleDeliver}
        subtitle={`Tap “CHECKOUT” to mark this ticket as delivered:`}
        title={`Was ${ticketId} returned to the owner?`}
      />
    </div>
  );
};
export default TimeStatus;
