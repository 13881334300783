import React, { useState } from "react";
import inputmobilesvg from "../../../assets/imgs/icons/input-mobile-svg.svg";
import main_image from "../../../assets/imgs/login.png";
import ticket_svg from "../../../assets/imgs/icons/ticket-svg.svg";
import VerticallyCenteredModal from "../../common/Modal";
import Logo from "../../../Logo";
import { useIsOwnerPhoneNumberExist, useCountryCodesGetAll } from "../../../hooks/common";
import { useParams } from "react-router";
import Spinner from "../../common/spinner/spinner";
import Loading from "../../common/Loader/Loading";

export const OwnerLogin = ({phoneNumber, countryCode,changePhoneNumber, changeCountryCode, handleSubmitLogin, isLoading }) => {
  
  const { data, isLoading: CountryCodesLoading } = useCountryCodesGetAll();
  if(isLoading || CountryCodesLoading){
    return <Loading/>;
  }
  return (

    <div className="container-fluid full-height">
      <div className="row row-height">
         {/* <!-- *********=========COL CARDS=========*********  --> */}
      <div className="col-lg-7  p-4 p-md-5 ">
      <div className="mb-sm-5 mt-sm-3">
              <Logo />
            </div>
     
          <h1 className="bold text-primary pt-sm-5 pb-sm-2">Login</h1>
          <h5 className="mb-sm-5">Enter your phone number to login, we will send you and OTP on that number</h5>
          <form onSubmit={handleSubmitLogin}>
          <div className="col-sm-6 ">
            <label className=" small  mb-2 mt-3">Phone Number</label>
            <div className="d-flex align-items-center  px-2 rounded-2 mb-3 svg-input">
              <img src={inputmobilesvg} alt="" srcSet="" />
              <div className="d-flex">
                <select
                value={countryCode}
                  style={{ maxWidth: "30%" }} 
                  onChange={changeCountryCode}
                  className="p-2 border-0  text-dark outline-0 form-control"
                >
                  {data?.map((options)=>(
                    <option key={options.id} value={options.id}>{options.code}({options.country})</option>
                  ))}
                </select>
                <input
                  style={{ maxWidth: "70%" }}
                  type="number"
                  onChange={changePhoneNumber}
                  value={phoneNumber}
                  placeholder="Please enter"
                  className="flex-grow-1 p-2 border-0 outline-0 text-dark form-control"
                />
              </div>
            </div>
          </div>
          {/* <!-- *********========= MODAL LONCH BUTTON =========*********  --> */}
          <button
            type="submit"
            className="btn rounded-4 px-5 mt-5 medium btn-primary submit-button"
            // data-bs-toggle="modal"
            // data-bs-target="#successfullmessage" 
            disabled={!phoneNumber}
          >
            {isLoading ? <Spinner /> : "NEXT"}
          </button>
          </form>
        </div>
        {/* <!-- *********=========COL SATRTED=========*********  --> */}
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
        
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
       
     
      </div>
    </div>


  );
};
