import Modal from "react-bootstrap/Modal";
import ticket_modal from "../../assets/imgs/icons/tick-modal.png";
// import ErrorModal from "../../assets/imgs/icons/error-modal.png";
import logo_modal from "../../assets/imgs/icons/logo.png"

export const VerticallyCenteredModal = ({
  type = "success",
  title,
  subtitle,
  show,
  closeModal,
  actionName,
  handleAction,
  handleReset,
  resetAction,
}) => {
  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={closeModal}
      >
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn-close small"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <Modal.Body>
          <div className="text-center">
            <div className="mb-4">
              <img
                src={type === "success" ? ticket_modal : logo_modal}
                alt=""
              />
            </div>
            <h6 className="semibold">{title}</h6>
            <p className="">{subtitle}</p>
            <div className="d-flex justify-content-center gap-3 mb-2">
              {resetAction ? (
                <button
                  type="button"
                  className="btn rounded-4 px-3  btn-primary text-uppercase"
                  // data-bs-toggle="modal"
                  // data-bs-target="#successfullmessage"
                  onClick={() => {
                    handleReset && handleReset();
                    closeModal();
                  }}
                >
                  RESET
                </button>
              ) : null}
                    {actionName ? (
                <button
                  type="button"
                  className="btn rounded-4 px-3  btn-primary text-uppercase"
                  // data-bs-toggle="modal"
                  // data-bs-target="#successfullmessage"
                  onClick={() => {
                    handleAction && handleAction();
                    closeModal();
                  }}
                >
                  {actionName}
                </button>
              ) : null}
              <button
                type="button"
                className="btn btn-close-modal px-3 rounded-5 text-uppercase"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerticallyCenteredModal;
