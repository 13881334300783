import React from "react";
import { VehicleVerification } from "../VehicleVerification";
import { useTicketData } from "../../../hooks/common";
import { useParams } from "react-router";
import RecivingTimer from "../RecivingTimer";
import TimeStatus from "../TimeStatus";
import useInterval from "../../common/useInterval";
import NotFoundPage from "../../common/NoTicketFound";
import Loading from "../../common/Loader/Loading";

const AttendentContainer = () => {
  const { ticketId } = useParams();
  const { data, refetch, isLoading } = useTicketData(ticketId);
  const interval = 5000; // 5 seconds

  // Use the useInterval hook to refetch the query at the specified interval
  useInterval(() => {
    refetch();
  }, interval);
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {(data?.parkingStatus === "UnUsed" || data?.parkingStatus === "Parked") &&
      !data?.vihicleNumber ? (
        <VehicleVerification
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          countryCode={data?.code}
        />
      ) : null}
      {data?.vihicleNumber &&
      !data?.deliveryTimeInMinutes &&
      data?.isPaymentMethodCreatedOrSelected != true && data?.parkingStatus == "Waiting" ? (
        <RecivingTimer
          status={"Parked"}
          // status={data?.parkingStatus}
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          countryCode={data?.code}
          attendentName={data?.attendentName}
          vehicleNumber={data?.vihicleNumber}
          parkingTime={data?.parkingTime}
        />
      ) : null}

{data?.vihicleNumber &&
      !data?.deliveryTimeInMinutes &&
      data?.isPaymentMethodCreatedOrSelected != true && data?.parkingStatus == "Parked" ? (
        <RecivingTimer
          status={"Parked"}
          // status={data?.parkingStatus}
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          countryCode={data?.code}
          attendentName={data?.attendentName}
          vehicleNumber={data?.vihicleNumber}
          parkingTime={data?.parkingTime}
        />
      ) : null}

      {data?.vihicleNumber &&
      !data?.deliveryTimeInMinutes &&
      data?.isPaymentMethodCreatedOrSelected == true ? (
        <RecivingTimer
          status={data?.parkingStatus}
          // status={data?.parkingStatus}
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          countryCode={data?.code}
          attendentName={data?.attendentName}
          vehicleNumber={data?.vihicleNumber}
          parkingTime={data?.parkingTime}
        />
      ) : null}

      {data?.deliveryTimeInMinutes && data?.parkingStatus !== "Delivered" ? (
        <TimeStatus
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          countryCode={data?.code}
          attendentName={data?.attendentName}
          vehicleNumber={data?.vihicleNumber}
          parkingTime={data?.parkingTime}
          time={parseInt(data?.deliveryTimeInMinutes)}
          startInterval={data?.deliveryStaringIntervel}
        />
      ) : null}
      {data?.parkingStatus === "Delivered" ? (
        <NotFoundPage ticketId={data?.ticketId} />
      ) : null}
    </>
  );
};

export default AttendentContainer;
