import React, { useState } from "react";
import Logo from "../../Logo";
import main_image from "../../assets/imgs/main-img.png";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import mobile_svg from "../../assets/imgs/icons/mobile-svg.svg";
import contect_svg from "../../assets/imgs/icons/contect-svg.svg";
import car_svg from "../../assets/imgs/icons/car-svg.svg";
import PaymentCharges from "../../assets/imgs/icons/PaymentCharges.svg";
import calender_svg from "../../assets/imgs/icons/calender-svg.svg";
import { ReactComponent as EditIcon } from "../../assets/imgs/icons/edit-pen.svg";
import {
  useRequestVehicle,
  useUpdateOwnerPhoneNumber,
} from "../../hooks/common";
import { useParams } from "react-router";
import VerticallyCenteredModal from "../common/Modal";
import UpdateNumberModal from "../common/UpdateNumModal";
import { getMonth } from "../../utils/getMonth";
import Loading from "../common/Loader/Loading";
const TicketDetails = ({
  ticketId,
  ownerContact,
  countryCode,
  countryCodeId,
  attendentName,
  vehicleNumber,
  parkingTime,
  isWaiting,
  price,
  isPaymentMethodCreatedOrSelected,
}) => {
  const { ticketId: ticketIdBackend } = useParams();
  const [show, setShow] = useState(false);
  const [showUpdateNum, setShowUpdateNum] = useState(false);
  const [disableRequest, setDisableRequest] = useState(false);
  const [UpdateNumber, setUpdateNumber] = useState(ownerContact);
  const [UpdateCode, setUpdateCode] = useState(countryCodeId);

  const toggleModal = () => {
    setShow((prevState) => {
      return !prevState;
    });
  };
  const toggleModalUpdateNum = () => {
    setShowUpdateNum((prevState) => {
      return !prevState;
    });
  };
  const handleRequestVehicleSuccess = (statusCode) => {
    if (statusCode === 200) setDisableRequest(true);
  };
  const handleSuccess = () => {};
  const { mutate, isLoading } = useRequestVehicle(
    handleRequestVehicleSuccess,
    handleSuccess
  );
  const {
    mutate: updateNum,
    isLoading: isUpdating,
    isRefetching,
  } = useUpdateOwnerPhoneNumber();
  const handleSubmit = () => {
    mutate({ ticketIdBackend });
  };
  const handleUpdateNum = () => {
    updateNum({
      phoneNumber: UpdateNumber,
      ticketIdBackend,
      countryCodeId: UpdateCode,
    });
  };

  if (isLoading || isUpdating || isRefetching) {
    return <Loading />;
  }
  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7 side-card p-4 p-md-5 justify-content-center align-items-center">
         
          <h4 className="bold">
          Send Vehicle <span className="text-primary bold"> Request</span>
          </h4>
          {isWaiting ? (
            <p className="mobile-text mb-1">
              Your vehicle request has been submitted! We'll update the ETA once
              it's been accepted. If you're ready to go, you can start heading
              to the valet stand.
            </p>
          ) : (
            <p className="mobile-text mb-1">
              {attendentName
                ? ` Ready to leave? Tap "REQUEST" to send a vehicle request notification to ${attendentName}.  `
                : ` Ready to leave? Tap "REQUEST" to send a vehicle request. `}
            </p>
          )}
          {/* <!-- Button trigger modal --> */}

          <button
            type="button"
            className={`btn rounded-4 px-5 mt-3 medium btn-primary submit-button text-uppercase ${isWaiting && 'd-none'}`}
            // data-bs-toggle="modal"
            // data-bs-target="#confirmodal"
            onClick={() => {
              toggleModal();
            }}
            disabled={!parkingTime || !vehicleNumber || isWaiting}
          >
            Request
          </button>

          <div className="pt-4 pt-md-5">

          <div className="mb-3">
            <h4 className="bold ">
              Your <span className="text-primary bold">Ticket</span> Details
            </h4>
          </div>

          <div className="gray-bg details-section p-0 p-md-3 rounded-3">
            <div className="row g-2">
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={ticket_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Parking Ticket</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{ticketId}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={mobile_svg} alt="" srcSet="" />
                  <p className="m-0 w-100 d-flex align-items-center">
                    Owner Contact #
                    {isPaymentMethodCreatedOrSelected ? (
                      ""
                    ) : (
                      <button
                        className="ms-2 btn btn-light-success d-flex justify-content-center align-items-center border-0 px-2"
                        type="button"
                        onClick={() => {
                          toggleModalUpdateNum();
                        }}
                      >
                        <EditIcon />
                      </button>
                    )}
                  </p>
                </div>
                <p className="semibold properties ms-4 mb-0">
                  {countryCode} {ownerContact}
                </p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={contect_svg} alt="" srcSet="" />
                  <p className="m-0  w-100">Attendant Name</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{attendentName}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={car_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Vehicle License Plate #</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{vehicleNumber}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={calender_svg} alt="" srcSet="" />
                  <p className="m-0   w-100">Parked at</p>
                </div>
                {parkingTime ? (
                  <p className="semibold properties ms-4 mb-0">
                    {`${getMonth(
                      String(new Date(parkingTime).getMonth())
                    )} ${String(new Date(parkingTime).getDate()).padStart(
                      2,
                      "0"
                    )}, ${new Date(parkingTime).getFullYear()} ${String(
                      new Date(parkingTime).getHours()
                    ).padStart(2, "0")}:${String(
                      new Date(parkingTime).getMinutes()
                    ).padStart(2, "0")}`}
                  </p>
                ) : (
                  <p className="semibold properties ms-4 mb-0">
                    Not Parked Yet
                  </p>
                )}
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={PaymentCharges} alt="" srcSet="" />
                  <p className="m-0 w-100 d-flex align-items-center">
                    Parking Charges
                  </p>
                </div>
                <p className="semibold properties ms-4 mb-0">${price}/hour</p>
              </div>
            </div>
          </div>
          </div>

          <VerticallyCenteredModal
            show={show}
            closeModal={toggleModal}
            type="error"
            actionName="CONFIRM"
            handleAction={handleSubmit}
            subtitle={`Please hit ${"CONFIRM"} and we will notify ${attendentName}`}
            title="Ready to head out? We’ll let the parking attendant know and text you with the ETA once your request has been accepted!"
          />

          <UpdateNumberModal
            show={showUpdateNum}
            closeModal={toggleModalUpdateNum}
            type="error"
            actionName="Update"
            handleAction={handleUpdateNum}
            inputValue={UpdateNumber}
            inputOnChange={(e) => {
              setUpdateNumber(e.target.value);
            }}
            SelectValue={UpdateCode}
            SelectOnChange={(e) => {
              setUpdateCode(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default TicketDetails;
