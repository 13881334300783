import Modal from "react-bootstrap/Modal";
import DeleteModelIcon from "../../../assets/imgs/icons/DeleteModelIcon.svg";

export const DeletePaymentMethodModel = ({ show, closeModal, handleDelete }) => {
  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={closeModal}
      >
        {/* <div className="modal-header border-0">
          <button
            type="button"
            className="btn-close small"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div> */}
        <Modal.Body>
          <div className="text-center p-4">
            <div className="mb-5">
              <img src={DeleteModelIcon} alt="" />
            </div>
            <h5 className="semibold">Delete Payment Method</h5>
            <p className="mb-0">Are you sure to delete this payment method </p>
           
            <div className="d-flex justify-content-center gap-3 mb-2 mt-5">
              <button
                type="button"
                className="btn rounded-4 px-5  btn-close-modal "
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn rounded-4 px-5 btn-primary "
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeletePaymentMethodModel;
