import React, { useEffect, useState } from "react";
import { OwnerLogin } from "./OwnerLogin";
import {
  useIsOwnerPhoneNumberExist,
  useTicketsByOwnerPhoneNumber,
  useOTPVerification,
} from "../../../hooks/common";
import { OtpVerification } from "./OtpVerification";
import { HistoryTable } from "./HistoryTable";
import Loading from "../../common/Loader/Loading";

function OwnerHistoryContainer() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberExist, setIsPhoneNumberExist] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [pageRender, setPageRender] = useState(1);
  const [otpStatus, setOtpStatus] = useState("");
  const [page, setpage] = useState(1);
  const [otp, setOtp] = useState();
  const [rowPerPage, setrowPerPage] = useState(5);

  const loginSuccessData = (data) => {
    if (data.status === "otp sent") {
      setPageRender(2);
    } else if (data.status === "Not Found") {
      setIsPhoneNumberExist("Phone number doesn't exist");
    }
  };
  const otpOnSuccess = (data) => {
    if (data.status === "Otp is verified.") {
      setPageRender(3);
      refetch();
    } else if (data.status === "Not Verified. !") {
      setOtpStatus("Invalid OTP");
    }
  };

  const { mutate, isLoading } = useIsOwnerPhoneNumberExist(loginSuccessData);
  const { mutate: OTPMutate, isLoading: otploding } =
    useOTPVerification(otpOnSuccess);

  const {
    data: historyTable,
    refetch,
    isLoading: TicketsByOwnerPhoneNumberLoding,
  } = useTicketsByOwnerPhoneNumber(page, rowPerPage, countryCode, phoneNumber);
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };
  const handlePhoneNumberChange = (event) => {
    const noSpecialChars = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setPhoneNumber(noSpecialChars);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({ phoneNumber: phoneNumber, countryCodeId: countryCode });
  };
  const otpResend = () => {
    mutate({ phoneNumber: phoneNumber, countryCodeId: countryCode });
  };
  const otpSubmit = (e) => {
    e.preventDefault();
    OTPMutate({
      phoneNumber: phoneNumber,
      otp: otp,
      countryCodeId: countryCode,
    });
  }; 
  const handleFocus = () => {
    setOtpStatus("");
  };

  const handleFocusPhone = () => {
    setIsPhoneNumberExist("");
  };
  const [totalPages, setTotalPages] = useState();
  var pageCountNum =
    historyTable?.list?.recordCount / historyTable?.list?.pageSize;
  useEffect(() => {
    var pageCount = parseInt(pageCountNum, 0);
    setTotalPages(pageCount + 1);
  }, [pageCountNum]);

  const handleRowPerPageChange = (e) => {
    setrowPerPage(e.target.value);
  };

  const onPageChange = (pageNumber) => {
    setpage(pageNumber);
  };

  if (isLoading || TicketsByOwnerPhoneNumberLoding) {
    return <Loading />;
  }
  return (
    <>
      {pageRender === 1 ? (
        <OwnerLogin
          phoneNumber={phoneNumber}
          isPhoneNumberExist={isPhoneNumberExist}
          handleFocusPhone={handleFocusPhone}
          countryCode={countryCode}
          changeCountryCode={(e) => {
            handleCountryCodeChange(e);
          }}
          changePhoneNumber={(e) => {
            handlePhoneNumberChange(e);
          }}
          handleSubmitLogin={(e) => {
            handleSubmit(e);
          }}
          isLoading={isLoading}
        />
      ) : pageRender === 2 ? (
        <OtpVerification
          otp={otp}
          setOtp={setOtp}
          otpSubmit={otpSubmit}
          isLoading={otploding}
          otpMessage={otpStatus}
          otpResend={otpResend}
          handleFocus={handleFocus}
        />
      ) : pageRender === 3 ? (
        <HistoryTable
          historyTable={historyTable}
          page={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
          rowPerPage={rowPerPage}
          setrowPerPage={(e) => {
            handleRowPerPageChange(e);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default OwnerHistoryContainer;
