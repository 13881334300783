import React from "react";
// import VerticallyCenteredModal from "../../common/Modal";
import Logo from "../../../Logo";
export const HistoryTable = ({
  historyTable,
  totalPages,
  onPageChange,
  page,
  rowPerPage,
  setrowPerPage,
}) => {
  // const [show, setShow] = useState(false);
  // const toggleModal = () => {
  //     setShow((prevState) => {
  //         return !prevState;
  //     });
  // };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  return (
    <div>
      <div className="px-5 pt-5">
        <Logo />
      </div>
      <div className="p-5">
        <h4 className="mb-4">Your Parking History</h4>
        <div className="container-fluid mt-1 bg-white card-Shadow rounded-2 px-0">
          <div className="table-responsive" style={{ height: "60vh" }}>
            <table className="table Search-Employees table-borderless table-striped table-hover">
              <thead
                className="Employees-border sticky-top"
                style={{ zIndex: 1 }}
              >
                <tr className="small">
                  <th scope="col" className="pb-3 ps-4 dashed-border-bottom">
                    #
                  </th>
                  <th
                    scope="col"
                    className="pb-3 dashed-border-bottom"
                    style={{ minWidth: "150px" }}
                  >
                    Ticket ID #
                  </th>
                  <th
                    scope="col"
                    className="pb-3 dashed-border-bottom"
                    style={{ minWidth: "150px" }}
                  >
                    Attendent Name
                  </th>
                  <th
                    scope="col"
                    className="pb-3 dashed-border-bottom"
                    style={{ minWidth: "100px" }}
                  >
                    Owner Phone Number
                  </th>
                  <th scope="col" className="pb-3 dashed-border-bottom">
                    Vehicle Number
                  </th>
                  <th scope="col" className="pb-3 dashed-border-bottom">
                    Status
                  </th>
                  <th scope="col" className="pb-3 dashed-border-bottom">
                    Parked Time
                  </th>
                  <th scope="col" className="pb-3 dashed-border-bottom">
                    Delivered Time
                  </th>
                </tr>
              </thead>
              <tbody className="small">
                {historyTable?.list?.data?.map((rowData, index) => (
                  <tr className="mt-4">
                    <th scope="row" className="ps-4 pe-2">
                      {index + 1}
                    </th>
                    <td>{rowData.ticketId}</td>
                    <td>{rowData.attendent}</td>
                    <td>{rowData.ownerPhoneNumber}</td>
                    <td>{rowData.vihicleNumber}</td>
                    <td className=" ">
                      <span
                        className={`badge ${
                          rowData.parkingStatus == "Parked"
                            ? "badge-Parked"
                            : "badge-Delivered"
                        }    p-2`}
                      >
                        {rowData.parkingStatus}
                      </span>
                    </td>
                    <td>{rowData.parkingTime}</td>
                    <td>{rowData.deliveryTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="employee-displaying d-flex flex-wrap-reverse align-items-center justify-content-between p-3 border-top">
            <div className="d-flex flex-wrap-reverse align-items-center mt-md-0 mt-3">
              <p className="mb-0 mt-sm-0 mt-1 displaying-records">
                Displaying {page} for {rowPerPage} records
              </p>
              <div className="d-flex justify-content-center align-items-center ms-sm-0 ms-2">
                <select
                  value={rowPerPage}
                  onChange={setrowPerPage}
                  className="ms-sm-4 form-select input-flied gray border-0"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                  <option value={50}>50</option>
                </select>
                <div className="d-flex align-items-center">
                  <div className="ms-sm-3 ms-2">
                    <div className="loader"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-nav">
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-end mb-0">
                  <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                    <a
                      className="page-link next-prev-btn"
                      href="#"
                      onClick={() => onPageChange(page - 1)}
                    >
                      Previous
                    </a>
                  </li>

                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      className={`page-item ${number === page ? "active" : ""}`}
                    >
                      <a
                        className="page-link next-prev-btn"
                        href="#"
                        onClick={() => onPageChange(number)}
                      >
                        {number}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`page - item ${
                      page === totalPages ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link next-prev-btn"
                      href="#"
                      onClick={() => onPageChange(page + 1)}
                    >
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* <VerticallyCenteredModal
        show={show}
        closeModal={toggleModal}
        title=" Phone Number Added Successfully...!"
        subtitle="  You will receive parking details on your phone number via SMS."
    /> */}
    </div>
  );
};
