import React from "react";
import Modal from "react-bootstrap/Modal";
export const CustomTimeModal = ({ 
  show,
  closeModal,
  handleSave,  
  inputTime,
  inputTimeOnchange,
}) => {

  return (
    <>
      <Modal
        size="sm"
        centered
        show={show}
        onHide={closeModal}
      >
        <div className="modal-header">
          <h5 className="modal-title medium">
             Custom Time
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <Modal.Body>
          <div className="modal-body p-0">
            <div className="">
              <label className="form-label  small ">
                Enter Custom Time
              </label>
              <p className="translate-mins float-end me-5">mins</p>
              <input
                type="number"
                min="0"
                className="form-control py-2 rounded-3"
               value={inputTime}
                onChange={inputTimeOnchange}
                placeholder="Please Enter"
              />
            </div>
          </div>
          <div className="modal-footer p-0 pt-3 border-0">
            <button
              type="button"
              className="btn btn-close-modal px-3 py-1 text-uppercase"
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary px-3 py-1 text-uppercase"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomTimeModal;
