import Modal from "react-bootstrap/Modal";
import PaymentSuccessIcon from "../../../assets/imgs/icons/PaymentSuccessIcon.svg";


export const SuccessPaymentModal = ({ show, closeModal }) => {
  return (
    <>
      <Modal
        size="md"
        centered
        show={show}
        onHide={closeModal}
      >
        <div className="modal-header border-0">
          <button
            type="button"
            className="btn-close small"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <Modal.Body>
          <div className="text-center">
            <div className="mb-4">
              <img
                src={PaymentSuccessIcon}
                alt=""
              />
            </div>
            <h5 className="semibold">Transaction Successful...!</h5>
            <p className="mb-0">Your transaction was completed successfully.</p>
            <p className="">Thank you!</p>

         
            <div className="d-flex justify-content-center gap-3 mb-2">
              <button
                type="button"
                className="btn rounded-4 px-5  btn-primary "
                onClick={closeModal}
              >
                PROCEED TO ADD FEEDBACK
              </button> 
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SuccessPaymentModal;
