import React, { useEffect, useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Logo from "../../Logo";
import main_image from "../../assets/imgs/main-img.png";
import { ReactComponent as AddIcon } from "../../assets/imgs/icons/AddIcon.svg";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import mobile_svg from "../../assets/imgs/icons/mobile-svg.svg";
import contect_svg from "../../assets/imgs/icons/contect-svg.svg";
import car_svg from "../../assets/imgs/icons/car-svg.svg";
import calender_svg from "../../assets/imgs/icons/calender-svg.svg";
import stripepay from "../../assets/imgs/icons/stripepay.png";
import Appalpay from "../../assets/imgs/icons/Appalpay.png";
import { ReactComponent as EditIcon } from "../../assets/imgs/icons/edit-pen.svg";
import {
  usePaymentMethodCreationOrSettingDefault,
  usePostPaymentCheckout,
} from "../../hooks/common";
import { useParams } from "react-router";
import VerticallyCenteredModal from "../common/Modal";
import UpdateNumberModal from "../common/UpdateNumModal";
import { getMonth } from "../../utils/getMonth";
import Loading from "../common/Loader/Loading";
import SuccessPaymentModal from "../common/Modals/SuccessPaymentModal";
import ErrorPaymentModal from "../common/Modals/ErrorPaymentModal";
const Payment = ({
  deliveryTime,
  parkingTime,
  hourlyPrice,
  amount,
  parkingDuration,
  countryCode,
  ownerContact,
}) => {
  const { ticketId: ticketIdBackend } = useParams();

  const [activeTip, setActiveTip] = useState(undefined);
  const [customTip, setCustomTip] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const toggleModal = () => {
    setShow((prevState) => {
      return !prevState;
    });
  };

  const [showError, setShowError] = useState(false);
  const toggleModalError = () => {
    setShowError((prevState) => {
      return !prevState;
    });
  };

  var tipOptions = [
    { value: (amount * 0.15).toFixed(2), label: "15%" },
    { value: (amount * 0.2).toFixed(2), label: "20%" },
    { value: (amount * 0.25).toFixed(2), label: "25%" },
  ];

  const handleTipClick = (value) => {
    setActiveTip(value);
    setCustomTip(undefined);
  };

  const handleCustomTipChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setCustomTip(value);
      setActiveTip(undefined);
    }
  };

  var activeTipValue = parseFloat(activeTip) || 0;
  var customTipValue = parseFloat(customTip) || 0;
  // Calculate total price with tip
  var totalPriceWithTip = (amount + (activeTipValue || customTipValue)).toFixed(
    2
  );

  const onSuccess = (data) => {
    if (
      data?.statusCode == 400 &&
      data?.message == "Tip must be positive value!"
    ) {
      setShowError(true);
      setError(data?.message);
    } else if (data?.StatusCode === 500) {
      setShowError(true);
      setError(data?.Message);
    } else {
      setShow(true);
    }
  };

  const onError = (error) => {
    setShowError(true);
    setError(error.message);
  };

  const { mutate, isLoading: isPaymenting } = usePostPaymentCheckout(
    onSuccess,
    onError
  );

  const { mutate: addNewCard, isLoading: isAddingNew } =
    usePaymentMethodCreationOrSettingDefault();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    var dataPrice = {
      phoneNumber: countryCode + "" + ownerContact,
      amount: parseFloat(activeTip) || parseFloat(customTip) || 0,
      confirmation: true,
      ticketId: ticketIdBackend,
    };
    mutate(dataPrice);

    setLoading(false);
  };

  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7 side-card p-4 p-md-5 justify-content-center align-items-center">
        <div className="mb-5">
            <h4 className="bold ">  <span className="text-primary bold">Payment </span> Details</h4>
            <p>
            Select a tip percentage or add a custom amount
            </p>
          </div>

          <div className="d-flex justify-content-center mb-5 flex-wrap">
            <div className="d-flex flex-wrap gap-3">
              {tipOptions?.map((tip) => (
                <button
                  key={tip.value}
                  className={`btn btn-lg rounded-3 px-3 py-1 btn-light-payment  d-flex align-items-baseline gap-1 ${
                    activeTip === tip.value ? "active" : ""
                  }`}
                  onClick={() => handleTipClick(tip.value)}
                >
                  ${tip.value} <span className="fs-12">({tip.label})</span>
                </button>
              ))}
              <div
                className={`rounded-3 px-3 py-1 fs-12 d-flex align-items-center payment-custom-btn ${
                  customTip ? "bg-primary text-white" : "bg-light"
                }`}
              >
                <span className="d-flex align-items-center">
                  <span className="fs-6">|</span>
                  <input
                    style={{ maxWidth: "84px", fontSize: "1.25rem" }}
                    placeholder="Custom"
                    min={0}
                    type="number"
                    className={`flex-grow-1 border-0 outline-0 form-control p-0 ${
                      customTip ? "bg-primary text-white" : "text-dark"
                    } `}
                    value={customTip}
                    onChange={handleCustomTipChange}
                    onWheel={(e) => e.target.blur()}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="text-center mb-5">
            <h1 className="bold mb-0 text-primary">${totalPriceWithTip} </h1>
            <p className="mb-0">Total Parking Charges, Including Tip</p>
          </div>
          <div className="d-flex gap-3 justify-content-center flex-wrap">
            <button
              type="submit"
              className="btn btn-primary px-4 rounded-5 text-uppercase"
              disabled={loading || isPaymenting}
              onClick={handleSubmit}
            >
              {loading || isPaymenting ? (
                <div
                  className="spinner-border spinner-border-sm payment-spinner"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "CHECKOUT "
              )}
            </button>
         
            <button
              type="submit"
              className="btn btn-outline-primary px-4 rounded-5"
              disabled={isAddingNew}
              onClick={() => addNewCard(ticketIdBackend)}
            >
              {isAddingNew ? (
                <div
                  className="spinner-border spinner-border-sm payment-spinner"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <>
                  <AddIcon className="me-2 mb-1" />
                  NEW CARD
                </>
              )}
            </button>
          </div>
          {error && <div className="text-danger text-center pt-2">{error}</div>}

              <div className="mt-4 mt-md-5">
              <div className="mb-3">
              <h4 className="bold ">  <span className="text-primary bold">Parking </span> Details</h4>
            <p>
            Please review the details of your stay and fees below:
            </p>
          </div>

          <div className="gray-bg details-section p-0 p-md-3 rounded-3">
            <div className="row g-3">
              <div className="col-lg-4 col-md-4">
                <div className="flex-grow-1 d-flex d-md-block justify-content-between flex-row-reverse ">
                  <div className="d-flex align-items-center">
                    {parkingTime ? (
                      <p className="semibold properties mb-0">
                        {`${getMonth(String(new Date(parkingTime).getMonth()))} 
                    ${String(new Date(parkingTime).getDate()).padStart(
                      2,
                      "0"
                    )}, 
                    ${new Date(parkingTime).getFullYear()}
                     ${String(new Date(parkingTime).getHours()).padStart(
                       2,
                       "0"
                     )}:${String(new Date(parkingTime).getMinutes()).padStart(
                          2,
                          "0"
                        )}`}
                      </p>
                    ) : (
                      <p className="semibold properties mb-0">Not Parked Yet</p>
                    )}
                  </div>
                  <p className="properties mb-0">Arrival Time</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="flex-grow-1 d-flex d-md-block justify-content-between flex-row-reverse">
                  <div className="d-flex align-items-center">
                    {deliveryTime ? (
                      <p className="semibold properties mb-0">
                        {`${getMonth(
                          String(new Date(deliveryTime).getMonth())
                        )} 
                    ${String(new Date(deliveryTime).getDate()).padStart(
                      2,
                      "0"
                    )}, 
                    ${new Date(deliveryTime).getFullYear()}
                     ${String(new Date(deliveryTime).getHours()).padStart(
                       2,
                       "0"
                     )}:${String(new Date(deliveryTime).getMinutes()).padStart(
                          2,
                          "0"
                        )}`}
                      </p>
                    ) : (
                      <p className="semibold properties mb-0">Not Parked Yet</p>
                    )}
                  </div>
                  <p className="properties mb-0">Departure Time</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="flex-grow-1 d-flex d-md-block justify-content-between flex-row-reverse">
                  <div className="d-flex align-items-center">
                    <p className="semibold m-0 w-100">{parkingDuration}</p>
                  </div>
                  <p className="properties mb-0">Time Parked</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="flex-grow-1 d-flex d-md-block justify-content-between flex-row-reverse">
                  <div className="d-flex align-items-center">
                    <p className="semibold m-0 w-100">${hourlyPrice}/hour</p>
                  </div>
                  <p className="properties mb-0">Parking Charges</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="flex-grow-1 d-flex d-md-block justify-content-between flex-row-reverse">
                  <div className="d-flex align-items-center">
                    <p className="semibold m-0 w-100">${amount}</p>
                  </div>
                  <p className="properties  mb-0">Total Charges</p>
                </div>
              </div>
            </div>
          </div>
         
              </div>

        </div>
      </div>
      <SuccessPaymentModal show={show} closeModal={toggleModal} />
      <ErrorPaymentModal show={showError} closeModal={toggleModalError} />
    </div>
  );
};
export default Payment;
