import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ErrorPage } from "./components/VehiceOwner/ErrorPage";
import VehicleOwnerContainer from "./components/VehiceOwner/VehicleOwnerContainer";
import AttendentContainer from "./components/Attendent/AttendentContainer/AttendentContainer";
import { Feedback } from "./components/VehiceOwner/Feedback";
import Oldfeedback from "./components/VehiceOwner/Oldfeedback";
import TicketDetails from "./components/VehiceOwner/TicketDetails";
import { OwnerMobile } from "./components/VehiceOwner/OwnerMobile";
import VehicheStatus from "./components/VehiceOwner/VehicheStatus";
import OwnerHistoryContainer from "./components/VehiceOwner/OwnerHistory/OwnerHistoryContainer";
import Payment from "./components/VehiceOwner/Payment";
import StripeContext from "./StripeContext";
import LandingPage from "./components/LandingPage/LandingPage";
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/*" element={<ErrorPage />} /> */}
        <Route path="/*" element={<LandingPage />} />
        <Route path="/owner/:ticketId" element={<VehicleOwnerContainer />} />
        <Route path="/attendent/:ticketId" element={<AttendentContainer />} />
        <Route path="/feedback/:ticketId" element={<Feedback />} />
        <Route path="/Oldfeedback/:ticketId" element={<Oldfeedback />} />
        <Route path="/ticketdetails" element={<TicketDetails />} />
        <Route path="/ownermobile" element={<OwnerMobile />} />
        <Route path="/vehichelstatus" element={<VehicheStatus />} />
        <Route path="/OwnerHistory" element={<OwnerHistoryContainer />} />
        {/* <Route
          path="/Payment/:ticketId"
          element={
            <StripeContext>
              <Payment />
            </StripeContext>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
