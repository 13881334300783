import React, { useState } from "react";
import "../../assets/scss/tab.scss";
import Logo from "../../Logo";
import { useSetFeedback } from "../../hooks/common";
import main_image from "../../assets/imgs/main-img.png";
import angery_face from "../../assets/imgs/angery-face.png";
import sad_face from "../../assets/imgs/sad-face.png";
import serious_face from "../../assets/imgs/serious-face.png";
import smiling_face from "../../assets/imgs/smiling-face.png";
import big_smile_face from "../../assets/imgs/big-smile-face.png";
import img1 from "../../assets/imgs/1.svg";
import img2 from "../../assets/imgs/2.svg";
import img3 from "../../assets/imgs/3.svg";
import img4 from "../../assets/imgs/4.svg";
import img5 from "../../assets/imgs/5.svg";
import paypal_image from "../../assets/imgs/icons/paypal.webp";
import stripepay_image from "../../assets/imgs/icons/stripepay.png";
import Appalpay_image from "../../assets/imgs/icons/Appalpay.png";
import { useParams, useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../common/Loader/Loading";
export const Feedback = () => {
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const handleSuccess = ()=> {};
  const { mutate, isLoading } = useSetFeedback(handleSuccess);
  const [feedBack, setFeedBack] = useState("");
  const [stars, setStars] = useState(0);
  const [feedbacktab, setFeedBackTab] = useState(true);
  const [expiaryDate, setExpiaryDate] = useState();
  const handleSendFeedback = () => {
    mutate({
      ticketId,
      stars,
      feedback: feedBack,
    });
    navigate("/");
  };
  if(isLoading){
    return <Loading/>;
  }
  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        <div className="col-lg-5 p-4 p-md-5">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7 side-card p-4 p-md-5">
          <h4 className="bold my-4">Payment & Feedback Hub</h4>
          <ul className="nav nav-underline mb-4">
            <li className="nav-item">
              <button
                class={`nav-link payment-feedback-tabs ${
                  feedbacktab ? "" : "active"
                } `}
                onClick={() => {
                  setFeedBackTab(false);
                }}
              >
                <span className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 32 32"
                  >
                    <g data-name="Group 62647" transform="translate(-568 -185)">
                      <rect
                        data-name="Rectangle 16917"
                        width="32"
                        height="32"
                        rx="5"
                        transform="translate(568 185)"
                        style={{ opacity: 0.05 }}
                      />
                      <g data-name="Group 62646">
                        <path
                          data-name="Path 26575"
                          d="M343-358.928v2.467h6.468a3.4 3.4 0 0 1 .347.005.614.614 0 0 1 .577.61.607.607 0 0 1-.574.612c-.23.019-.463.007-.694.007h-6.11v7.384h17.251v-4.146c0-.487.23-.767.617-.768s.617.28.617.767v4.049a1.24 1.24 0 0 1-1.34 1.351q-8.521.005-17.042 0a1.245 1.245 0 0 1-1.361-1.37v-10.835a1.244 1.244 0 0 1 1.361-1.369q3.259-.007 6.516 0c.481 0 .764.24.758.627s-.285.607-.778.607h-6.169z"
                          transform="translate(232.247 556.835)"
                        />
                        <path
                          data-name="Path 26576"
                          d="M364.392-364.555c0-.488.008-.977 0-1.465a.7.7 0 0 1 .455-.729c1.391-.6 2.78-1.2 4.178-1.777a.847.847 0 0 1 .586-.007c1.411.583 2.817 1.177 4.208 1.8a.8.8 0 0 1 .425.567 40.322 40.322 0 0 1-.108 4.077 6.519 6.519 0 0 1-2.888 4.672 14.994 14.994 0 0 1-1.516.985.828.828 0 0 1-.675.065 9.186 9.186 0 0 1-3.766-3.373 7.491 7.491 0 0 1-.891-3.587c-.012-.411 0-.822 0-1.233zm1.238-1.182c0 .752.007 1.5 0 2.24a5.874 5.874 0 0 0 2.738 5.257c1.2.8.735.8 1.92 0a5.473 5.473 0 0 0 2.63-3.987c.129-1.157.11-2.331.157-3.491-1.238-.531-2.415-1.039-3.6-1.535a.462.462 0 0 0-.324.013c-1.17.493-2.336.996-3.521 1.503z"
                          transform="translate(219.482 561.582)"
                        />
                        <path
                          data-name="Path 26577"
                          d="M349.233-341.951c-.372 0-.743.005-1.114 0a.626.626 0 0 1-.7-.607.618.618 0 0 1 .677-.624q1.171-.012 2.343 0a.618.618 0 0 1 .677.624.626.626 0 0 1-.7.607c-.389.005-.786 0-1.183 0z"
                          transform="translate(229.05 547.262)"
                        />
                        <path
                          data-name="Path 26578"
                          d="M371.963-357.477c.632-.79 1.227-1.562 1.861-2.3a.951.951 0 0 1 .64-.322.6.6 0 0 1 .366 1.013c-.51.661-1.04 1.306-1.561 1.958-.272.341-.54.687-.82 1.022a.617.617 0 0 1-1.084-.037c-.4-.57-.778-1.148-1.155-1.729a.613.613 0 0 1 .133-.913.616.616 0 0 1 .893.235c.24.341.47.692.727 1.073z"
                          transform="translate(216.28 556.799)"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                Make Payment
              </button>
            </li>
            <li className="nav-item">
              <button
                class={`nav-link payment-feedback-tabs ${
                  feedbacktab ? "active" : ""
                } `}
                onClick={() => {
                  setFeedBackTab(true);
                }}
              >
                <span className="me-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 32 32"
                  >
                    <g data-name="Group 62647" transform="translate(-568 -185)">
                      <rect
                        data-name="Rectangle 16917"
                        width="32"
                        height="32"
                        rx="5"
                        transform="translate(568 185)"
                        style={{ opacity: 0.05 }}
                      />
                      <g data-name="Group 62651">
                        <path
                          data-name="Path 26579"
                          d="M405.828-350.106c-.545 0-1.089-.02-1.632 0a2.584 2.584 0 0 1-2.733-2.732c.059-2.187.013-4.376.016-6.565a2.492 2.492 0 0 1 2.646-2.651h6.092a1.357 1.357 0 0 1 .326.018.5.5 0 0 1 .417.524.485.485 0 0 1-.448.494 2.53 2.53 0 0 1-.364.015h-5.909a1.5 1.5 0 0 0-1.72 1.718v6.456a1.472 1.472 0 0 0 1.664 1.653h2.043c.54 0 .72.2.7.737-.017.449-.035.9-.04 1.349a1.182 1.182 0 0 0 .076.3 1.037 1.037 0 0 0 .324-.1c.908-.672 1.8-1.367 2.72-2.024a1.446 1.446 0 0 1 .763-.251c1.045-.028 2.091-.008 3.136-.013a1.424 1.424 0 0 0 1.546-1.545c.009-1.264 0-2.529 0-3.793a3.381 3.381 0 0 1 .009-.364.494.494 0 0 1 .523-.5.5.5 0 0 1 .518.505c.021.266.007.535.007.8v3.355a2.5 2.5 0 0 1-2.6 2.6c-.973.006-1.946-.01-2.918.01a1.111 1.111 0 0 0-.592.2c-.836.606-1.66 1.23-2.479 1.859a1.25 1.25 0 0 1-1.455.2 1.263 1.263 0 0 1-.648-1.321c.029-.288.012-.59.012-.934z"
                          transform="translate(173.537 556.892)"
                        />
                        <path
                          data-name="Path 26580"
                          d="M426.389-363.022c.34-.808-.113-1.5-.749-2.175a.838.838 0 0 1 .469-1.455 2.476 2.476 0 0 0 2.017-1.465.846.846 0 0 1 1.516.01 2.471 2.471 0 0 0 2.022 1.457.833.833 0 0 1 .468 1.433 2.506 2.506 0 0 0-.779 2.4.823.823 0 0 1-1.2.877 2.549 2.549 0 0 0-2.53-.011c-.651.345-1.266-.158-1.234-1.071zm.136-2.735c.3.352.5.62.731.863a.984.984 0 0 1 .281.862c-.042.322-.052.649-.081 1.036.332-.129.621-.217.886-.352a1.094 1.094 0 0 1 1.074 0c.27.139.566.227.978.388a2.224 2.224 0 0 1 .834-2.725c-.432-.11-.746-.209-1.066-.265a.966.966 0 0 1-.729-.534c-.158-.292-.351-.566-.593-.949a2.21 2.21 0 0 1-2.315 1.676z"
                          transform="translate(160.029 560.582)"
                        />
                        <path
                          data-name="Path 26581"
                          d="M414.6-351.534h-2.769c-.415 0-.657-.242-.609-.593s.31-.463.632-.463H417.356c.286 0 .54.057.647.361.129.368-.146.693-.6.7-.936 0-1.871-.005-2.803-.005z"
                          transform="translate(168.028 551.548)"
                        />
                        <path
                          data-name="Path 26582"
                          d="M413.244-345.437c-.485 0-.971.006-1.457 0-.368-.006-.573-.2-.575-.515a.5.5 0 0 1 .568-.522q1.475-.01 2.951 0a.524.524 0 0 1 .582.515.522.522 0 0 1-.575.522c-.498.006-.996 0-1.494 0z"
                          transform="translate(168.028 548.094)"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                Submit Feedback
              </button>
            </li>
          </ul>
          {feedbacktab ? (
            <div>
              <div className=" rounded-3 mb-4">
                <div>
                  <p className="semibold mb-0">How did we do?</p>
                </div>
                <div className="Feedback-emoji d-flex gap-3 gap-md-5 justify-content-center align-items-center my-5">
                  <div
                    onClick={() => {
                      setStars(1);
                    }}
                  >
                    <img
                      className="pe-auto"
                      src={stars === 1 ? img1 : angery_face}
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => {
                      setStars(2);
                    }}
                  >
                    <img
                      className="pe-auto"
                      src={stars === 2 ? img2 : sad_face}
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => {
                      setStars(3);
                    }}
                  >
                    <img
                      className="pe-auto"
                      src={stars === 3 ? img3 : serious_face}
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => {
                      setStars(4);
                    }}
                  >
                    <img
                      className="pe-auto"
                      src={stars === 4 ? img4 : smiling_face}
                      alt=""
                    />
                  </div>
                  <div
                    onClick={() => {
                      setStars(5);
                    }}
                  >
                    <img
                      className="pe-auto"
                      src={stars === 5 ? img5 : big_smile_face}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <p className=" semibold">Leave additional comments/notes:</p>
              <div>
                <textarea
                  rows={4}
                  onChange={(e) => {
                    setFeedBack(e.target.value);
                  }}
                  className="form-control text-dark border-0 p-3 small"
                  placeholder="Enter your message here..."
                ></textarea>
              </div>
              <button
                type="button"
                onClick={handleSendFeedback}
                disabled={!feedBack.length}
                className="btn rounded-5 px-5 mt-4 medium btn-primary"
              >
                SUBMIT FEEDBACK
              </button>
            </div>
          ) : (
            <div className=" rounded-3 mb-4">
              <div>
                <p className="semibold">
                  Select Your Preferred Payment Method and Proceed to Pay
                </p>
              </div>
              <div className="row mb-4">
                <div className="col-md-5">
                  <p>Select Payment Method</p>
                </div>
                <div className="col-md-7">
                  <div className="d-flex gap-3 flex-wrap">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radioNoLabel"
                        id="radioNoLabel1"
                        value=""
                        aria-label="..."
                      />
                      <span className="px-2">
                        <img src={paypal_image} alt="" />
                      </span>
                      Paypall
                    </div>
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radioNoLabel"
                        id="radioNoLabel1"
                        value=""
                        aria-label="..."
                      />
                      <span className="px-2">
                        <img src={stripepay_image} alt="" />
                      </span>
                      Stripe Payment
                    </div>
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="radioNoLabel"
                        id="radioNoLabel1"
                        value=""
                        aria-label="..."
                      />
                      <span className="px-2">
                        <img src={Appalpay_image} alt="" />
                      </span>
                      Apple Pay
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="semibold">Add Card details</p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className=" rounded-2 mb-3 svg-input">
                    <input
                      type="text"
                      placeholder="Card Number"
                      className="flex-grow-1 p-2 border-0 outline-0 text-dark form-control"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="rounded-2 mb-3 svg-input">
                    <DatePicker
                      placeholderText="Expiration Date"
                      showIcon
                      selected={expiaryDate}
                      onChange={(date) => {
                        setExpiaryDate(date);
                      }}
                      className="d-flex p-2 border-0 outline-0 text-dark form-control justify-content-between"
                    />
                    {/* <input type={inputType} onFocus={changeType} placeholder="Expiration Date"  className="d-flex p-2 border-0 outline-0 text-dark form-control justify-content-between" value=""/> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className=" rounded-2 mb-3 svg-input">
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="flex-grow-1 p-2 border-0 outline-0 text-dark form-control"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className=" rounded-2 mb-3 svg-input">
                    <input
                      type="text"
                      placeholder="CVC"
                      className="flex-grow-1 p-2 border-0 outline-0 text-dark form-control"
                      value=""
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className=" rounded-2 mb-3 svg-input">
                    <input
                      type="text"
                      placeholder="Enter Amount"
                      className="flex-grow-1 p-2 border-0 outline-0 text-dark form-control"
                      value=""
                    />
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn rounded-5 px-5 mt-4 medium btn-primary"
              >
                Pay Now
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
